import { createAction, handleActions } from "redux-actions";

interface State {
  pageVisible: boolean;
}

export const pageVisibleChange = createAction("PAGE_VISIBLE_CHANGE");

const initialState = {
  pageVisible: true,
};

export default handleActions(
  {
    PAGE_VISIBLE_CHANGE: (state: State) => {
      return {
        ...state,
        pageVisible: document.visibilityState === "visible",
      };
    },
  },
  initialState
);
