import React, { useEffect, useState } from "react";
import { Card, Box, CardMedia, CardContent, Typography, CardActions, Button, TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { clearLogin, sendOneTimeCode, sendPhone, setOneTimeCode } from "../../reducers/login-reducer";
import { getLoginDetails } from "../../reducers/profile-reducer";
import { setCredentials } from "../../reducers/connection-reducer";
import NimbioLogo100 from "../../assets/image/nimbio-logo-100x100.png";
// Testing
import endpoints from "../../routes/endpoints";

const Verify = (props) => {
  const theme = useTheme();
  const { lastCodeRequest } = props.loginState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendCodeDisable, setSendCodeDisable] = useState(
    lastCodeRequest !== null && Date.now() - lastCodeRequest < 5000
  );

  const checkTimer = () => {
    if (sendCodeDisable && Date.now() - (lastCodeRequest || 0) > 5000) {
      setSendCodeDisable(false);
    }
  };

  useEffect(() => {
    let otcTimer = setInterval(() => checkTimer(), 250);
    return () => {
      clearInterval(otcTimer);
    };
  }, [lastCodeRequest]);

  const onKeyPress = (e) => {
    if (e.which === 13) {
      submitOTU();
    }
  };

  const resendOTC = () => {
    props.dispatch(sendPhone(props.loginState.phoneNumber));
    setSendCodeDisable(true);
  };

  const submitOTU = () => {
    dispatch(sendOneTimeCode(props.loginState.phoneNumber, props.loginState.oneTimeCode));
  };

  const goBackToPhone = () => {
    dispatch(clearLogin());
    navigate("/login");
  };

  useEffect(() => {
    if (!props.loginState.sendPhoneSuccess) {
      navigate("/login");
      return;
    }
  }, [props.loginState.sendPhoneSuccess, props.history, navigate]);

  useEffect(() => {
    if (props.loginState.oneTimeCodeSuccess) {
      dispatch(setCredentials(props.loginState.auth_id, props.loginState.secret));
      dispatch(getLoginDetails());

      if (props.profileState.loaded) {
        if (
          props.profileState.details.first_name === "" ||
          props.profileState.details.last_name === "" ||
          props.profileState.details.first_name === null ||
          props.profileState.details.last_name === null
        ) {
          navigate("/name");
          return;
        }
      }
      navigate("/");
      return;
    }
  }, [props.history, props.loginState, props.profileState, dispatch, navigate]);

  return (
    <div style={classes(theme).outerdiv}>
      <Card style={classes(theme).card}>
        <CardMedia component="img" style={classes(theme).media} image={NimbioLogo100} title="Nimbio" />
        <CardContent>
          <Typography variant="h6" gutterBottom style={classes(theme).signinText}>
            Enter Code
          </Typography>
          <Typography variant="subtitle1" gutterBottom style={classes(theme).signinSubtitle}>
            to continue to nimbio
          </Typography>
          <Typography variant="body1" gutterBottom style={classes(theme).onetimecodebody}>
            The phone number you entered should have received a text message with a code, please enter it below
          </Typography>
          <TextField
            id={"nimbio-testing" + endpoints.VERIFY + "-input"}
            value={props.loginState.oneTimeCode || ""}
            autoFocus
            placeholder="One Time Code"
            label={props.loginState.oneTimeError}
            onKeyPress={onKeyPress}
            margin="normal"
            inputProps={{ style: classes(theme).otuInput }}
            error={props.loginState.oneTimeError !== ""}
            onChange={(event) => props.dispatch(setOneTimeCode(event.target.value))}
            data-testid="verify-input"
          />
        </CardContent>
        <Box sx={classes(theme).otcActions}>
          <Button
            sx={classes(theme).resendOTC}
            size="small"
            disabled={sendCodeDisable}
            variant="outlined"
            onClick={resendOTC}>
            Resend Code
          </Button>
          <Button size="small" variant="outlined" onClick={goBackToPhone}>
            Change Phone
          </Button>
        </Box>
        <CardActions>
          <Button
            id={"nimbio-testing" + endpoints.VERIFY + "-button"}
            variant="contained"
            style={classes(theme).button}
            onClick={() => submitOTU()}>
            Next
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const classes = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerdiv: {
    padding: 30,
  },
  card: {
    maxWidth: 375,
    marginLeft: "auto",
    marginRight: "auto",
  },
  media: {
    minHeight: 100,
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signinText: {
    textAlign: "center",
  },
  signinSubtitle: {
    textAlign: "center",
    paddingBottom: 22,
  },
  otuInput: {
    fontSize: 24,
    letterSpacing: 3,
  },
  otcActions: {
    marginLeft: 1,
    marginRight: 1,
    marginTop: 4,
    display: "flex",
    flexDirection: "row",
  },
  resendOTC: {
    marginRight: 1,
    marginLeft: 1,
  },
});

const mapStateToProps = (state) => {
  return {
    loginState: state.loginState,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(Verify);
