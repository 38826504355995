import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DoorbellIcon from "@mui/icons-material/Doorbell";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Badge from "@mui/material/Badge";
import { connect, useSelector } from "react-redux";
import { Avatar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getMemberDetail } from "../../../reducers/memberdetail-reducer";
import { getHomeDetail } from "../../../reducers/homedetail-reducer";
import { getColor, getShortName } from "../../../utilities/materialAvatar";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  chevron: {
    paddingLeft: theme.spacing(4),
  },
  subkeyIcon: {
    color: "#666666",
    marginLeft: 1,
  },
  moveOutDateIcon: {
    color: "#666666",
    marginTop: 1,
    marginLeft: 1,
  },
  homeMemberCountIcon: {
    color: "#666666",
  },
});

const MemberListItem = (props) => {
  const theme = useTheme();
  const { show_home_members_in_list } = useSelector((state) => state.homeDetailState);
  const details = props.details;

  const handleMemberListItemClick = (account_community_id) => {
    props.dispatch(getMemberDetail(account_community_id, props.community_id));
  };

  const handleHomeListItemClick = (home_id) => {
    props.dispatch(getHomeDetail(home_id, props.community_id));
  };

  return (
    <>
      {details.is_home ? (
        <Box>
          <ListItemButton sx={styles(theme).nested} onClick={() => handleHomeListItemClick(details["id"])}>
            <ListItemIcon>
              <Avatar sx={{ backgroundColor: getColor(details["id"]) }}>
                <HomeIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText>
              <Typography>{details["home_address"]}</Typography>
              {show_home_members_in_list
                ? details["member_names"].map((name) => (
                    <Typography color={"text.secondary"} key={name} sx={{ marginTop: 1, marginLeft: 2 }}>
                      {name}
                    </Typography>
                  ))
                : null}
            </ListItemText>
            <Tooltip title={"Home Members"}>
              <Badge overlap="rectangular" badgeContent={details.members.length} sx={styles(theme).homeMemberCountIcon}>
                <PersonIcon sx={styles(theme).homeMemberCountIcon} />
              </Badge>
            </Tooltip>
            <ChevronRight color="action" sx={styles(theme).chevron} />
          </ListItemButton>
        </Box>
      ) : (
        <Box data-testid={"accepted-item"}>
          <ListItemButton
            sx={styles(theme).nested}
            onClick={() => handleMemberListItemClick(details["account_community_id"])}
            data-testid={"accepted-item-open"}>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: getColor(details["id"]) }}>
                {getShortName(details.first_name, details.last_name)}
              </Avatar>
            </ListItemIcon>
            <ListItemText secondary={details["phone_numbers"][0]}>
              {details["first_name"]} {details["last_name"]}
            </ListItemText>

            {details.subkey_summary.total_subkey_count > 0 && (
              <Tooltip title="Total Subkeys">
                <Badge
                  overlap="rectangular"
                  badgeContent={details.subkey_summary.total_subkey_count}
                  sx={styles(theme).subkeyIcon}>
                  <VpnKeyIcon sx={styles(theme).subkeyIcon} />
                </Badge>
              </Tooltip>
            )}

            {details.subkey_summary.doorbell_subkey_count > 0 && (
              <Tooltip title="Doorbell Subkeys">
                <Badge
                  overlap="rectangular"
                  badgeContent={details.subkey_summary.doorbell_subkey_count}
                  sx={styles(theme).subkeyIcon}>
                  <DoorbellIcon sx={styles(theme).subkeyIcon} />
                </Badge>
              </Tooltip>
            )}
            {details.move_out_date ? (
              <Tooltip title={`Move Out Date - ${details.move_out_date}`}>
                <Box sx={styles(theme).moveOutDateIcon}>
                  <LocalShippingIcon sx={styles(theme).subkeyIcon} />
                </Box>
              </Tooltip>
            ) : null}
            <ChevronRight color="action" sx={styles(theme).chevron} />
          </ListItemButton>
        </Box>
      )}
    </>
  );
};

export default connect()(MemberListItem);
