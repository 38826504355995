import { combineReducers } from "redux";

import communityReducer from "./communities-reducer";
import profileReducer from "./profile-reducer";
import realmConnectionReducer from "./connection-reducer";
import homesReducer from "./homes-reducer";
import keysReducer from "./keys-reducer";
import membersReducer from "./members-reducer";
import memberDetailReducer from "./memberdetail-reducer";
import homeDetailReducer from "./homedetail-reducer";
import loginReducer from "./login-reducer";
import memberChildrenReducer from "./member-children-reducer";
import addInstallerReducer from "./add-installer-reducer";
import deviceStatusReducer from "./device-status-reducer";
import addAdminReducer from "./add-admin-reducer";
import accountReducer from "./account-reducer";
import accountGetKeys from "./account-get-keys";
import addDistributorReducer from "./add-distributor-reducer";
import adminCommunityListReducer from "./admin-community-list";
import adminHideKeyReducer from "./admin-hide-key-reducer";
import adminFindUserReducer from "./admin-find-user";
import adminUniversalSearchReducer from "./admin-universal-search-reducer";
import findCommunity from "./admin-find-community";
import holdOpenReducer from "./hold-open-reducers";
import appReducer from "./app-reducer";
import senseLineReducer from "./sense-line-reducer";
import getAccountGroupsReducer from "./admin-get-account-groups";
import getVendorsReducer from "./admin-get-vendors";
import getAccountVendorsReducer from "./admin-get-account-vendors";
import getVendorByUUIDReducer from "./admin-get-vendor-by-uuid-reducer";
import getAccountToAccountGroupsReducer from "./admin-get-account-account-groups";
import getBoxesAccountGroups from "./admin-get-boxes-account-groups";
import getBoxesVendors from "./admin-get-boxes-vendors";
import getLatchesVendors from "./admin-get-latches-vendors";
import adminMaintenanceModeReducer from "./admin-maintenance-mode-reducer";
import getVendorsByAccountReducer from "./admin-get-vendors-by-account";
import getKeysByVendorReducer from "./admin-get-vendor-keys";
import handleAddVendorAPIKeyReducer from "./admin-add-vendor-api-key";
import getContactsByVendorReducer from "./admin-get-vendor-contacts";
import getAccountVendorOpenLogsReducer from "./admin-get-account-vendor-open-logs";
import adminIntegratedLatchesReducer from "./admin-integrated-latches-reducer";
import pendingMemberDetailReducer from "./pending-member-detail-reducer";
import removedMemberDetailReducer from "./removed-member-detail-reducer";

const reducers = combineReducers({
  accountGetKeysState: accountGetKeys,
  accountState: accountReducer,
  addAdmin: addAdminReducer,
  addDistributor: addDistributorReducer,
  addInstaller: addInstallerReducer,
  addVendorAPIKeyReducer: handleAddVendorAPIKeyReducer,
  adminCommunityList: adminCommunityListReducer,
  adminHideKey: adminHideKeyReducer,
  adminIntegratedLatches: adminIntegratedLatchesReducer,
  adminMaintenanceModeState: adminMaintenanceModeReducer,
  appState: appReducer,
  communityState: communityReducer,
  deviceStatus: deviceStatusReducer,
  findCommunity: findCommunity,
  findUser: adminFindUserReducer,
  universalSearch: adminUniversalSearchReducer,
  getAccountGroupsReducer: getAccountGroupsReducer,
  getAccountToAccountGroupsReducer: getAccountToAccountGroupsReducer,
  getAccountVendorOpenLogsReducer: getAccountVendorOpenLogsReducer,
  getAccountVendorsReducer: getAccountVendorsReducer,
  getBoxesAccountGroupsReducer: getBoxesAccountGroups,
  getBoxesVendorsReducer: getBoxesVendors,
  getContactsByVendorReducer: getContactsByVendorReducer,
  getKeysByVendorReducer: getKeysByVendorReducer,
  getLatchesVendorsReducer: getLatchesVendors,
  getVendorByUUIDReducer: getVendorByUUIDReducer,
  getVendorsByAccountReducer: getVendorsByAccountReducer,
  getVendorsReducer: getVendorsReducer,
  holdOpens: holdOpenReducer,
  homeDetailState: homeDetailReducer,
  homesState: homesReducer,
  keysState: keysReducer,
  loginState: loginReducer,
  memberChildren: memberChildrenReducer,
  memberDetailState: memberDetailReducer,
  pendingMemberDetailState: pendingMemberDetailReducer,
  removedMemberDetailState: removedMemberDetailReducer,
  membersState: membersReducer,
  profileState: profileReducer,
  realmState: realmConnectionReducer,
  senseLine: senseLineReducer,
});

export default reducers;
