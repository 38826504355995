import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import store from "../store";
import nimbioServer from "../server/endpoints";

interface State {
  phone_number: string;
  loading: boolean;
  loadingLatches: boolean;
  loadingToggle: boolean;
  error: boolean;
  errorLatches: boolean;
  errorToggle: boolean;
  result: any;
  updates: any;
  end_animations: any;
  device_latches: any[];
  connectionHistory: any[];
  search_term: string;
  search_term_lower: string;
  last_update: number;
  is_search: boolean | null;
  uspsMetadata: any;
  list_type?: string;
  deviceLoading: boolean;
  deviceLoaded: boolean;
  device: any;
  deviceError: boolean;
  total_length: number;
  page_number: number;
  isSearch: boolean;
}

const initialState: State = {
  phone_number: "",
  loading: true,
  loadingLatches: true,
  loadingToggle: true,
  error: false,
  errorLatches: false,
  errorToggle: false,
  result: false,
  updates: {},
  end_animations: {},
  device_latches: [],
  // TODO(rick) what's with the casing here
  connectionHistory: [],
  search_term: "",
  search_term_lower: "",
  last_update: 0,
  is_search: null,
  uspsMetadata: {},
  deviceLoading: false,
  deviceLoaded: false,
  deviceError: false,
  device: null,
  total_length: 0,
  page_number: 1,
  isSearch: false,
};

export const deviceListSetSearchTerm = createAsyncAction(
  "SET_DEVICE_SEARCH_TERM",
  async (query: string, page: number, size: number, query_type: string) => {
    const data = await call(
      nimbioServer.distributor.box.searchBoxStatus,
      { query, page, size, query_type },
      "device-status-reducer"
    );
    return { query, data };
  }
);

export const adminGetDevice = createAsyncAction("DEVICE_GET_BOX", (box_id: string) => {
  return call(nimbioServer.admin.box.getBox, box_id, "device-status-reducer");
});

export const adminGetDeviceStatusPaginated = createAsyncAction(
  "DEVICE_STATUS_PAGINATED",
  (page: number, size: number, order_by: string | null = null) => {
    return call(nimbioServer.distributor.box.getPaginatedStatus, { page, size, order_by }, "device-status-reducer");
  },
  () => "revealed"
);

export const adminGetDeviceStatusPaginatedHidden = createAsyncAction(
  "DEVICE_STATUS_PAGINATED_HIDDEN",
  (page: number, size: number) => {
    return call(nimbioServer.distributor.box.getPaginatedStatusHidden, { page, size }, "device-status-reducer");
  },
  () => "hidden"
);

export const adminUpdateDeviceListWithNewBox = createAction("DEVICE_UPDATE_NEW_BOX", (box: any) => {
  return box;
});

export const adminUpdateDevice = createAsyncAction("DEVICE_UPDATE_BOX", (box_id: string) => {
  return call(nimbioServer.distributor.box.getStatus, box_id, "device-status-reducer");
});

// TODO(rick) i'll come back to testing this, it's the cross-reducer state
export const distributorSetUpdate = createAction("DISTRIBUTOR_SET_UPDATE", (box_id: string, update_notice: string) => {
  if (store.getState().appState.pageVisible) {
    return {
      box_id,
      update_notice,
      timer: setTimeout(() => store.dispatch(distributorRemoveUpdate(box_id)), 6000),
    };
  } else {
    return false;
  }
});

export const distributorRemoveUpdate = createAction("DISTRIBUTOR_REMOVE_UPDATE", (box_id: string) => {
  return box_id;
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminHideDevice = createAsyncAction("ADMIN_DEVICE_HIDE", (box_id: string) => {
  return call(nimbioServer.admin.box.hide, [box_id], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminRevealDevice = createAsyncAction("ADMIN_DEVICE_REVEAL", (box_id: string) => {
  return call(nimbioServer.admin.box.reveal, [box_id], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceSetName = createAsyncAction("ADMIN_SET_DEVICE_NAME", (box_id: string, box_name: string) => {
  return call(nimbioServer.admin.box.setBoxName, [box_id, box_name], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceUpdateStats = createAsyncAction("ADMIN_DEVICE_UPDATE_STATS", (box_id: string) => {
  return call(nimbioServer.admin.box.requestBoxStats, [box_id], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceGetLatches = createAsyncAction("ADMIN_DEVICE_GET_LATCHES", (box_id: string) => {
  return call(nimbioServer.admin.box.getLatches, [box_id], "device-status-reducer");
});

export const adminDeviceSetUSPS = createAsyncAction(
  "ADMIN_DEVICE_SET_USPS",
  async (latch_id: string, allow_usps: boolean) => {
    const data = await call(nimbioServer.admin.latch.allowUSPS, [latch_id, allow_usps]);
    return {
      type: "ADMIN_DEVICE_SET_USPS",
      response: data,
      latch_id: latch_id,
      allow_usps: allow_usps,
    };
  }
);

export const adminDeviceGetUSPSMetadata = createAsyncAction("ADMIN_DEVICE_GET_USPS_METADATA", (latchId: string) => {
  return call(nimbioServer.admin.latch.getUSPSMetadata, [latchId], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceSetUSPSMetadata = createAsyncAction(
  "ADMIN_DEVICE_SET_USPS_METADATA",
  async (latchId: string, uspsMetadata: any) => {
    await call(nimbioServer.admin.latch.setUSPSMetadata, [latchId, uspsMetadata], "device-status-reducer");
    return uspsMetadata;
  }
);

export const adminDeviceResetUSPSMetadata = createAction("ADMIN_DEVICE_RESET_USPS_METADATA", () => true);

export const adminDeviceSetTruckbays = createAsyncAction(
  "ADMIN_DEVICE_SET_TRUCKBAYS",
  async (latch_id: string, allow_truckbays: boolean) => {
    const data = await call(
      nimbioServer.admin.latch.allowTruckbays,
      [latch_id, allow_truckbays],
      "device-status-reducer"
    );
    return {
      type: "ADMIN_DEVICE_SET_TRUCKBAYS",
      response: data,
      latch_id: latch_id,
      allow_truckbays: allow_truckbays,
    };
  }
);

export const adminDeviceSetUPS = createAsyncAction(
  "ADMIN_DEVICE_SET_UPS",
  async (latch_id: string, allow_ups: boolean) => {
    const data = await call(nimbioServer.admin.latch.allowUPS, [latch_id, allow_ups], "device-status-reducer");
    return {
      type: "ADMIN_DEVICE_SET_UPS",
      response: data,
      latch_id: latch_id,
      allow_ups: allow_ups,
    };
  }
);

export const adminDeviceSetWaymo = createAsyncAction(
  "ADMIN_DEVICE_SET_WAYMO",
  async (latch_id: string, allow_waymo: boolean) => {
    const data = await call(nimbioServer.admin.latch.allowWaymo, [latch_id, allow_waymo], "device-status-reducer");
    return {
      type: "ADMIN_DEVICE_SET_WAYMO",
      response: data,
      latch_id: latch_id,
      allow_waymo: allow_waymo,
    };
  }
);

//
export const adminDeviceSetGrata = createAsyncAction(
  "ADMIN_DEVICE_SET_GRATA",
  async (latch_id: string, allow_grata: boolean) => {
    const data = await call(nimbioServer.admin.latch.allowGrata, [latch_id, allow_grata], "device-status-reducer");
    return {
      type: "ADMIN_DEVICE_SET_GRATA",
      response: data,
      latch_id: latch_id,
      allow_grata: allow_grata,
    };
  }
);

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceRestartBox = createAsyncAction("ADMIN_DEVICE_RESTART", (box_id) => {
  return call(nimbioServer.admin.box.restart, [box_id], "device-status-reducer");
});

// TODO(rick) i don't think these actions are handled anywhere
export const adminDeviceCheckUpdate = createAsyncAction("ADMIN_DEVICE_CHECK_UPDATE", (box_id) => {
  return call(nimbioServer.admin.box.checkUpdate, [box_id], "device-status-reducer");
});

export default handleActions(
  {
    DEVICE_GET_BOX_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        deviceLoading: false,
        deviceLoaded: true,
        device: payload,
        deviceError: payload === false,
        deviceLastUpdate: Date.now(),
      };
    },
    DEVICE_GET_BOX_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        deviceLoading: false,
        deviceLoaded: true,
        device: undefined,
        deviceError: payload,
        deviceLastUpdate: Date.now(),
      };
    },
    DEVICE_GET_BOX_LOADING: (state: State) => {
      return {
        ...state,
        deviceLoading: true,
        deviceLoaded: false,
        device: undefined,
        deviceError: false,
        deviceLastUpdate: Date.now(),
      };
    },
    DEVICE_UPDATE_NEW_BOX: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        result:
          state.list_type === "revealed"
            ? state.result.concat([payload])
            : state.list_type === "recent"
            ? [payload].concat(state.result)
            : state.result,
        error: payload === false,
        last_update: Date.now(),
      };
    },
    DEVICE_UPDATE_BOX_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        result: state.result.map((box: any) => {
          if (box.box === payload.box) {
            return payload;
          } else {
            return box;
          }
        }),
        error: payload === false,
        last_update: Date.now(),
      };
    },
    DEVICE_UPDATE_BOX_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        last_update: Date.now(),
      };
    },
    DEVICE_UPDATE_BOX_LOADING: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: true,
        error: payload === false,
        last_update: Date.now(),
      };
    },
    DEVICE_STATUS_PAGINATED_SUCCESS: (state: State, { payload, meta }: { payload: any; meta: any }) => {
      console.log("DEVICE_STATUS_PAGINATED_SUCCESS", meta);
      return {
        ...state,
        loading: false,
        result: payload.results,
        total_length: payload.total_length,
        page_number: payload.page_number,
        error: payload === false,
        list_type: meta,
        last_update: Date.now(),
        isSearch: false,
      };
    },
    DEVICE_STATUS_PAGINATED_ERROR: (state: State, { meta }: { meta: any }) => {
      return {
        ...state,
        loading: false,
        result: [],
        error: true,
        list_type: meta,
        last_update: Date.now(),
        isSearch: false,
      };
    },
    DEVICE_STATUS_LOADING: (state: State, { meta }: { meta: any }) => {
      return {
        ...state,
        // loading: true,
        error: false,
        search_term_lower: meta === state.list_type ? state.search_term_lower : "",
        last_update: -1,
        // result: false,
      };
    },
    DEVICE_STATUS_PAGINATED_HIDDEN_SUCCESS: (state: State, { payload, meta }: { payload: any; meta: any }) => {
      return {
        ...state,
        loading: false,
        result: payload.results,
        total_length: payload.total_length,
        page_number: payload.page_number,
        error: payload === false,
        list_type: meta,
        last_update: Date.now(),
        isSearch: false,
      };
    },
    DEVICE_STATUS_PAGINATED_HIDDEN_ERROR: (state: State, { meta }: { meta: any }) => {
      return {
        ...state,
        loading: false,
        result: [],
        error: true,
        list_type: meta,
        last_update: Date.now(),
        isSearch: false,
      };
    },
    SET_DEVICE_SEARCH_TERM_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const { data, query } = payload;
      const query_lower = query?.toLowerCase();
      return {
        ...state,
        loading: false,
        result: data.results,
        total_length: data.total_length,
        page_number: data.page_number,
        search_term: query,
        search_term_lower: query_lower,
        error: data === false,
        last_update: Date.now(),
        // TODO(rick) camelCase
        isSearch: true,
      };
    },
    SET_DEVICE_SEARCH_TERM_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        result: [],
        error: true,
        last_update: Date.now(),
        isSearch: true,
      };
    },
    DISTRIBUTOR_SET_UPDATE: (state: State, { payload }: { payload: any }) => {
      if (payload === false) {
        return state;
      }
      if (payload.box_id in state.end_animations) {
        if (state.end_animations[payload.box_id].timer) {
          clearTimeout(state.end_animations[payload.box_id].timer);
        }
      }
      return {
        ...state,
        updates: {
          ...state.updates,
          [payload.box_id]: payload.update_notice,
        },
        end_animations: {
          ...state.end_animations,
          [payload.box_id]: {
            timer: payload.timer,
          },
        },
      };
    },
    DISTRIBUTOR_REMOVE_UPDATE: (state: State, { payload }: { payload: any }) => {
      const newUpdates = state.updates;
      delete newUpdates[payload];
      const newAnimations = state.end_animations;
      delete newAnimations[payload];
      return {
        ...state,
        updates: {
          ...newUpdates,
        },
        end_animations: {
          ...newAnimations,
        },
      };
    },
    ADMIN_DEVICE_RESET_LATCHES: (state: State) => {
      return {
        ...state,
        device_latches: [],
      };
    },
    ADMIN_DEVICE_GET_LATCHES_LOADING: (state: State) => {
      return {
        ...state,
        loadingLatches: true,
        errorLatches: false,
      };
    },
    ADMIN_DEVICE_GET_LATCHES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingLatches: false,
        errorLatches: payload,
      };
    },
    ADMIN_DEVICE_GET_LATCHES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        device_latches: payload,
        loadingLatches: false,
        errorLatches: false,
      };
    },
    ADMIN_DEVICE_GET_USPS_METADATA_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_DEVICE_GET_USPS_METADATA_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        uspsMetadata: payload,
        loading: false,
        error: false,
      };
    },
    ADMIN_DEVICE_GET_USPS_METADATA_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_DEVICE_SET_USPS_METADATA_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_DEVICE_SET_USPS_METADATA_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        uspsMetadata: {
          ...state.uspsMetadata,
          ...payload,
        },
        loading: false,
        error: false,
      };
    },
    ADMIN_DEVICE_SET_USPS_METADATA_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_DEVICE_RESET_USPS_METADATA: (state: State) => {
      return {
        ...state,
        uspsMetadata: {},
      };
    },
    // TODO(rick) write a helper for these, they only differ by the field that's changing
    ADMIN_DEVICE_SET_WAYMO_LOADING: (state: State) => {
      return {
        ...state,
        loadingToggle: true,
        errorToggle: false,
      };
    },
    ADMIN_DEVICE_SET_WAYMO_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingToggle: false,
        errorToggle: payload,
      };
    },
    ADMIN_DEVICE_SET_WAYMO_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const response = payload["response"];
      const latch_id = payload["latch_id"];
      const allow_waymo = payload["allow_waymo"];

      if (response === false) {
        return {
          ...state,
          errorToggle: true,
          loadingToggle: false,
        };
      }

      const updated_latches = state.device_latches.map((latch) => {
        if (latch.id === latch_id) {
          return {
            ...latch,
            allow_waymo: allow_waymo,
          };
        }
        return latch;
      });

      return {
        ...state,
        errorToggle: false,
        loadingToggle: false,
        device_latches: updated_latches,
      };
    },
    ADMIN_DEVICE_SET_USPS_LOADING: (state: State) => {
      return {
        ...state,
        loadingToggle: true,
        errorToggle: false,
      };
    },
    ADMIN_DEVICE_SET_USPS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingToggle: false,
        errorToggle: payload,
      };
    },
    ADMIN_DEVICE_SET_USPS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const response = payload["response"];
      const latch_id = payload["latch_id"];
      const allow_usps = payload["allow_usps"];

      if (response === false) {
        return {
          ...state,
          errorToggle: true,
          loadingToggle: false,
        };
      }

      const updated_latches = state.device_latches.map((latch) => {
        if (latch.id === latch_id) {
          return {
            ...latch,
            allow_usps: allow_usps,
          };
        }
        return latch;
      });

      return {
        ...state,
        loadingToggle: false,
        errorToggle: false,
        device_latches: updated_latches,
      };
    },
    ADMIN_DEVICE_SET_TRUCKBAYS_LOADING: (state: State) => {
      return {
        ...state,
        loadingToggle: true,
        errorToggle: false,
      };
    },
    ADMIN_DEVICE_SET_TRUCKBAYS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingToggle: false,
        errorToggle: payload,
      };
    },
    ADMIN_DEVICE_SET_TRUCKBAYS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const response = payload["response"];
      const latch_id = payload["latch_id"];
      const allow_truckbays = payload["allow_truckbays"];

      if (response === false) {
        return {
          ...state,
          errorToggle: true,
          loadingToggle: false,
        };
      }

      const updated_latches = state.device_latches.map((latch) => {
        if (latch.id === latch_id) {
          return {
            ...latch,
            allow_truckbays: allow_truckbays,
          };
        }
        return latch;
      });

      return {
        ...state,
        errorToggle: false,
        loadingToggle: false,
        device_latches: updated_latches,
      };
    },
    ADMIN_DEVICE_SET_GRATA_LOADING: (state: State) => {
      return {
        ...state,
        loadingToggle: true,
        errorToggle: false,
      };
    },
    ADMIN_DEVICE_SET_GRATA_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingToggle: false,
        errorToggle: payload,
      };
    },
    ADMIN_DEVICE_SET_GRATA_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const response = payload["response"];
      const latch_id = payload["latch_id"];
      const allow_grata = payload["allow_grata"];

      if (response === false) {
        return {
          ...state,
          errorToggle: true,
          loadingToggle: false,
        };
      }

      const updated_latches = state.device_latches.map((latch) => {
        if (latch.id === latch_id) {
          return {
            ...latch,
            allow_grata: allow_grata,
          };
        }
        return latch;
      });

      return {
        ...state,
        errorToggle: false,
        loadingToggle: false,
        device_latches: updated_latches,
      };
    },
    ADMIN_DEVICE_SET_UPS_LOADING: (state: State) => {
      return {
        ...state,
        loadingToggle: true,
        errorToggle: false,
      };
    },
    ADMIN_DEVICE_SET_UPS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loadingToggle: false,
        errorToggle: payload,
      };
    },
    ADMIN_DEVICE_SET_UPS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const response = payload["response"];
      const latch_id = payload["latch_id"];
      const allow_ups = payload["allow_ups"];

      if (response === false) {
        return {
          ...state,
          errorToggle: true,
          loadingToggle: false,
        };
      }

      const updated_latches = state.device_latches.map((latch) => {
        if (latch.id === latch_id) {
          return {
            ...latch,
            allow_ups: allow_ups,
          };
        }
        return latch;
      });

      return {
        ...state,
        errorToggle: false,
        loadingToggle: false,
        device_latches: updated_latches,
      };
    },
  },
  initialState
);
