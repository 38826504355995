import { handleActions } from "redux-actions";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

interface State {
  phone_number: string;
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
  result: boolean;
}

export const initialState = {
  phone_number: "",
  loading: false,
  error: false,
  errorMessage: null,
  result: false,
};

export const addInstallerCall = createAsyncAction("ADD_INSTALLER", (phone) => {
  return call(nimbioServer.distributor.account.setInstaller, [phone, 1], "add-installer-reducer");
});

export const clearInstallerState = createAction("CLEAR_INSTALLER_STATE", () => {
  return true;
});

export default handleActions(
  {
    ADD_INSTALLER_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: false,
      };
    },
    ADD_INSTALLER_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      return {
        ...state,
        loading: false,
        result: payload["data"],
        error: !payload["result"],
      };
    },
    ADD_INSTALLER_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: true,
        result: payload,
      };
    },
    CLEAR_INSTALLER_STATE: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);
