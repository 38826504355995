import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

interface State {
  phone_number: string;
  loading: boolean;
  error: boolean;
  result: addAdminResultDataType | boolean;
}

type addAdminResultDataType = {
  first: string;
  last: string;
};

export const initialState: State = {
  phone_number: "",
  loading: false,
  error: false,
  result: false,
};

export const addAdminCall = createAsyncAction("ADD_ADMIN", (phone) => {
  return call(nimbioServer.admin.account.setAdmin, [phone, 1], "add-admin-reducer");
});

export default handleActions(
  {
    ADD_ADMIN_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: false,
      };
    },
    ADD_ADMIN_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      return {
        ...state,
        loading: false,
        result: payload["data"],
        error: !payload["result"],
      };
    },
    ADD_ADMIN_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
  },
  initialState
);
