import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
/*eslint-disable*/
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import CardContent from "@mui/material/CardContent";
import AdminAppBar from "./AdminAppBar";
import { getAccount } from "../../../reducers/account-reducer";
import { accountGetKeys } from "../../../reducers/account-get-keys";
import AccountGroups from "../../common/Components/AccountGroups";
import Vendors from "../../common/Components/Vendors";
import { keyUpdateSubscription } from "../../../subscriptions/keyUpdateSubscription";
import { latchUpdateSubscription } from "../../../subscriptions/latchUpdateSubscription";
import AdminAccountDialog from "./AdminAccountDialog";
import AdminSelectAccountGroupDialog from "./AdminSelectAccountGroupDialog";
import AdminSelectVendorDialog from "./AdminSelectVendorDialog";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { useParams } from "react-router";
import AdminAccountKey from "./AdminAccountKey";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const NoAccountError = () => {
  return <div>An account with that ID was not found.</div>;
};

const PhoneNumber = ({ phoneNumber }) => {
  return (
    <Grid item>
      <Typography>{`${phoneNumber}`}</Typography>
    </Grid>
  );
};

const PhoneNumbers = ({ phoneNumbers }) => {
  if (phoneNumbers && phoneNumbers.length > 0) {
    return phoneNumbers.map((phoneNumber) => {
      return <PhoneNumber key={phoneNumber} phoneNumber={phoneNumber} />;
    });
  }
  return null;
};

const MoreButton = ({ enabled, onEditAccount }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  if (!enabled) {
    return null;
  }

  return (
    <>
      <IconButton
        size="large"
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label="Account More"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (onEditAccount) {
              onEditAccount();
            }
          }}>
          Edit Account
        </MenuItem>
      </Menu>
    </>
  );
};

const AccountGroupButton = ({ enabled, onAddAccountGroup }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  if (!enabled) {
    return null;
  }

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" aria-label="Account More" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (onAddAccountGroup) {
              onAddAccountGroup();
            }
          }}>
          Add Account Group
        </MenuItem>
      </Menu>
    </>
  );
};

const VendorButton = ({ enabled, onAddVendor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  if (!enabled) {
    return null;
  }

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" aria-label="Account More" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (onAddVendor) {
              onAddVendor();
            }
          }}>
          Add Vendor
        </MenuItem>
      </Menu>
    </>
  );
};

const AccountKeys = ({ keys, handleRefreshAccount }) => {
  if (keys) {
    return (
      <Box>
        {keys["keys"].map((key) => {
          return (
            <AdminAccountKey type={"accounts"} key={key.id} account_key={key} refresh_account={handleRefreshAccount} />
          );
        })}
      </Box>
    );
  }
  return null;
};

const AdminAccountView = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [accountGroupSelectOpen, setAccountGroupSelectOpen] = useState(false);
  const [vendorSelectOpen, SetVendorSelectOpen] = useState(false);

  const { accountId } = useParams();
  const dispatch = useDispatch();

  useSubscription(nimbioServer.internal.keyName, keyUpdateSubscription);
  useSubscription(nimbioServer.internal.latchName, latchUpdateSubscription);
  useSubscription(nimbioServer.internal.accountList, () => dispatch(getAccount(accountId)));
  useSubscription(nimbioServer.internal.keyList, () => dispatch(accountGetKeys(accountId)));

  useEffect(() => {
    dispatch(getAccount(accountId));
    dispatch(accountGetKeys(accountId));
  }, []);

  const handleRefreshAccountKeys = () => {
    dispatch(accountGetKeys(accountId));
  };

  const handlePmCommunityClick = (params) => {
    navigate("/admin/community/" + params.id);
  };

  if (props.loading || !props.loaded || !props.account) {
    return (
      <AdminAppBar selected="Account" {...props}>
        <div>Loading...</div>
      </AdminAppBar>
    );
  }

  const columns_pm_of = [
    {
      field: "name",
      headerName: "Community Name",
      flex: 1,
    },
    {
      field: "navigate",
      headerName: "Go To",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "center" }}>
          <IconButton onClick={() => handlePmCommunityClick(params)}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const columns_member_of = [
    {
      field: "name",
      headerName: "Community Name",
      flex: 1,
    },
    { field: "acceptance_status", headerName: "Acceptance Status", flex: 0.2 },
    {
      field: "navigate",
      headerName: "Go To",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "row", flex: 1, justifyContent: "center" }}>
          <IconButton onClick={() => handlePmCommunityClick(params)}>
            <NavigateNextIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const parseAcceptanceStatus = (acceptance_status) => {
    if (acceptance_status === 1) {
      return "Accepted";
    }
    if (acceptance_status === 0) {
      return "Pending";
    }
    if (acceptance_status === 2) {
      return "Removed";
    }
  };

  return (
    <AdminAppBar selected="Account" {...props}>
      {props.account && !props.loading && props.loaded && !props.error && (
        <>
          <Card>
            <CardHeader
              title="Account"
              action={
                <MoreButton
                  enabled={true}
                  onEditAccount={() => {
                    setOpen(true);
                    setAccount(props.account);
                  }}
                />
              }
            />
            <CardContent>
              <Typography variant="h5">{`${props.account.first_name} ${props.account.last_name}`}</Typography>
            </CardContent>
            <CardContent>
              <PhoneNumbers phoneNumbers={props.account.phone_numbers} />
            </CardContent>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Typography>{`Admin: ${props.account.admin ? "Yes" : "No"}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{`Installer: ${props.account.installer ? "Yes" : "No"}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{`Distributor: ${props.account.distributor ? "Yes" : "No"}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title="Property Manager Of" />
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => {
                    return row.id;
                  }}
                  rows={Object.keys(props.account.communities_user_is_cm_of).map((community_id) => ({
                    name: props.account.communities_user_is_cm_of[community_id],
                    id: community_id,
                  }))}
                  columns={columns_pm_of}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableColumnSelector={true}
                  disableColumnMenu={true}
                  autoHeight={true}
                  disableColumnFilter={true}
                  disableDensitySelector={true}
                  disableRowSelectionOnClick={true}
                  disableSortBy={true}
                />
              </Box>
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title="Community Member of" />
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => {
                    return row.id;
                  }}
                  rows={props.account.community_member_of.map((comm) => ({
                    name: comm.name,
                    id: comm.id,
                    acceptance_status: parseAcceptanceStatus(comm.community_accepted),
                  }))}
                  columns={columns_member_of}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableColumnSelector={true}
                  disableColumnMenu={true}
                  autoHeight={true}
                  disableColumnFilter={true}
                  disableDensitySelector={true}
                  disableRowSelectionOnClick={true}
                  disableSortBy={true}
                />
              </Box>
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader
              title="Vendors"
              action={
                <VendorButton
                  enabled={true}
                  onAddVendor={() => {
                    SetVendorSelectOpen(true);
                    setAccount(props.account);
                  }}
                />
              }
            />
            <CardContent>
              <Vendors account_id={props.account.id} />
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader
              title="Account Groups"
              action={
                <AccountGroupButton
                  enabled={true}
                  onAddAccountGroup={() => {
                    setAccountGroupSelectOpen(true);
                    setAccount(props.account);
                  }}
                />
              }
            />
            <CardContent>
              <AccountGroups account_id={props.account.id} />
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title="Account Keys" />
            <CardContent>
              <AccountKeys keys={props.keys} handleRefreshAccount={handleRefreshAccountKeys} />
            </CardContent>
          </Card>

          {accountGroupSelectOpen && (
            <AdminSelectAccountGroupDialog
              open={accountGroupSelectOpen}
              dispatch={props.dispatch}
              account_id={account.id}
              onClose={() => {
                setAccountGroupSelectOpen(false);
                setAccount(null);
              }}
            />
          )}

          {vendorSelectOpen && (
            <AdminSelectVendorDialog
              open={vendorSelectOpen}
              account_id={account.id}
              onClose={() => {
                SetVendorSelectOpen(false);
                setAccount(null);
              }}
            />
          )}

          {open && (
            <AdminAccountDialog
              open={open}
              account={account}
              onClose={() => {
                setOpen(false);
                setAccount(null);
              }}
            />
          )}
        </>
      )}
      {props.communityError && <NoAccountError />}
    </AdminAppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.accountState.account,
    keys: state.accountGetKeysState.keys,
    keysLoading: state.accountGetKeysState.loading,
    keysLoaded: state.accountGetKeysState.loaded,
    keysError: state.accountGetKeysState.error,
    loading: state.accountState.loading,
    loaded: state.accountState.loaded,
    error: state.accountState.error,
  };
};

export default connect(mapStateToProps)(AdminAccountView);
