import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

interface State {
  latches: boolean | any;
  error: boolean;
  loading: boolean;
  total_length_latch_results: number | null;
}

const initialState: State = {
  latches: false,
  error: false,
  loading: false,
  total_length_latch_results: null,
};

export const searchForIntegratedLatchesByAccountId = createAsyncAction(
  "ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID",
  (account_id: string, page: number, size: number) => {
    const prefix = "owner:";
    const query = prefix + account_id;
    return call(nimbioServer.admin.searchIntegratedLatches, [query, page, size, 1], "admin-integrated-latches-reducer");
  }
);

export const searchForIntegratedLatchesByLatchName = createAsyncAction(
  "ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME",
  (latch_name: string, page: number, size: number) => {
    return call(
      nimbioServer.admin.searchIntegratedLatches,
      [latch_name, page, size, 1],
      "admin-integrated-latches-reducer"
    );
  }
);

export default handleActions(
  {
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_LOADING: (state: State) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_SUCCESS: (
      state: State,
      { payload }: { payload: ServerResponseType }
    ) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          loading: false,
        };
      }

      return {
        ...state,
        latches: payload["data"]["results"],
        total_length_latch_results: payload["data"]["total_length"],
        error: !payload["result"],
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_LOADING: (state: State) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_SUCCESS: (
      state: State,
      { payload }: { payload: ServerResponseType }
    ) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          loading: false,
        };
      }
      return {
        ...state,
        latches: payload["data"]["results"],
        total_length_latch_results: payload["data"]["total_length"],
        error: !payload["result"],
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_ERROR: (state: State) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
  },
  initialState
);
