import { handleActions } from "redux-actions";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";
import LogRocket from "logrocket";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";
import { ServerResponseType } from "../utilities/nimbioTypes";

// Action Creators
export const getLoginDetails = createAsyncAction("LOGIN_DETAILS", () => {
  return call(nimbioServer.account.getLoginDetails, [], "profile-reducer");
});

export const clearProfile = createAction("CLEAR_PROFILE");

interface State {
  details: {
    first_name: string;
    last_name: string;
    communities: boolean;
    keys: boolean;
    subscriptions: string[];
    is_admin: boolean;
    is_community_manager: boolean;
    is_distributor: boolean;
    is_installer: boolean;
  };
  loading: boolean;
  error: any;
  loaded: boolean;
}

// Reducer
export const initialState = {
  details: {
    first_name: "",
    last_name: "",
    communities: false,
    keys: true,
    subscriptions: [],
    is_admin: false,
    is_community_manager: false,
    is_distributor: false,
    is_installer: false,
  },
  loading: false,
  error: null,
  loaded: false,
};

export default handleActions(
  {
    LOGIN_DETAILS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    LOGIN_DETAILS_SUCCESS: (state: State, { payload }: { payload: ServerResponseType | any }) => {
      if (payload["result"] === false) {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: !payload["result"],
        };
      }
      const account_id = payload?.subscriptions[0]
        ? payload.subscriptions[0].slice(payload.subscriptions[0].lastIndexOf(".") + 1)
        : "Unknown";

      LogRocket.identify(account_id, {
        name: payload.first_name + " " + payload.last_name,
        is_admin: payload.is_admin,
        is_community_manager: payload.is_community_manager,
        is_distributor: payload.is_distributor,
        is_installer: payload.is_installer,
      });

      return {
        ...state,
        details: payload,
        loading: false,
        loaded: true,
      };
    },
    LOGIN_DETAILS_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        loaded: true,
      };
    },
    CLEAR_PROFILE: () => {
      return initialState;
    },
  },
  initialState
);
