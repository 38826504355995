import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

type findUserResultType = {
  id: string;
  first_name: string;
  last_name: string;
  admin: boolean;
  distributor: boolean;
  installer: boolean;
  phone_numbers: string[];
  email: string;
};

interface State {
  phone_number: string;
  loading: boolean;
  error: boolean;
  result: findUserResultType[];
  loaded: boolean;
}

const initialState: State = {
  phone_number: "",
  loading: false,
  error: false,
  result: [],
  loaded: false,
};

export const findUser = createAsyncAction("ADMIN_FIND_USER", (first_name: string, last_name: string, phone: string) => {
  return call(nimbioServer.admin.findUser, [first_name, last_name, phone], "admin-find-user");
});

export default handleActions(
  {
    ADMIN_FIND_USER_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_FIND_USER_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      return {
        ...state,
        loading: false,
        result: payload["data"],
        error: !payload["result"],
        loaded: true,
      };
    },
    ADMIN_FIND_USER_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
