import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

interface State {
  loading: boolean;
  error: boolean;
  account: any;
  loaded: boolean;
}

const initialState = {
  loading: false,
  error: false,
  account: null,
  loaded: false,
};

export const getAccount = createAsyncAction("ADMIN_GET_ACCOUNT", (account_id) => {
  return call(nimbioServer.admin.getAccount, [account_id], "account-reducer");
});

export default handleActions(
  {
    ADMIN_GET_ACCOUNT_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        account: null,
      };
    },
    ADMIN_GET_ACCOUNT_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      return {
        ...state,
        loading: false,
        account: payload["data"],
        error: !payload["result"],
        loaded: true,
      };
    },
    ADMIN_GET_ACCOUNT_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        account: null,
        loaded: true,
      };
    },
  },
  initialState
);
