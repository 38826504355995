import { handleActions } from "redux-actions";
import { createAction } from "redux-promise-middleware-actions";

export const openRemovePendingMember = createAction("OPEN_REMOVE_PENDING_MEMBER", (option) => option);
export const closeRemovePendingMember = createAction("CLOSE_REMOVE_PENDING_MEMBER", () => false);
export const openAcceptPendingMember = createAction(
  "OPEN_ACCEPT_PENDING_MEMBER",
  (details, isMemberRemoved = false) => {
    return { details, isMemberRemoved };
  }
);
export const closeAcceptPendingMember = createAction("CLOSE_ACCEPT_PENDING_MEMBER", () => false);

interface State {
  details: any;
  loading: boolean;
  error: boolean;
  removePendingMemberOpen: boolean;
  acceptPendingMemberOpen: boolean;
  isMemberRemoved: boolean;
}

export const initialState: State = {
  details: {},
  loading: false,
  error: false,
  removePendingMemberOpen: false,
  acceptPendingMemberOpen: false,
  isMemberRemoved: false,
};

export default handleActions(
  {
    OPEN_ACCEPT_PENDING_MEMBER: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        acceptPendingMemberOpen: true,
        details: payload.details,
        isMemberRemoved: payload.isMemberRemoved,
      };
    },
    OPEN_REMOVE_PENDING_MEMBER: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        removePendingMemberOpen: true,
        details: payload,
      };
    },
    CLOSE_REMOVE_PENDING_MEMBER: (state: State) => {
      return {
        ...state,
        removePendingMemberOpen: false,
      };
    },
    CLOSE_ACCEPT_PENDING_MEMBER: (state: State) => {
      return {
        ...state,
        acceptPendingMemberOpen: false,
      };
    },
  },
  initialState
);
