import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  result: any;
  loaded: boolean;
}

const initialState = {
  loading: false,
  error: false,
  result: false,
  loaded: false,
};

export const findCommunity = createAsyncAction("ADMIN_FIND_COMMUNITY", (search_term: string) => {
  return call(nimbioServer.admin.findCommunity, [search_term], "admin-find-community");
});

export default handleActions(
  {
    ADMIN_FIND_COMMUNITY_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: [],
      };
    },
    ADMIN_FIND_COMMUNITY_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_FIND_COMMUNITY_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        result: [],
      };
    },
  },
  initialState
);
