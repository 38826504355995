import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import SenseLineConfigure from "./SenseLineConfigure";
import {
  communitySenseLineGetAllBoxSenseLines,
  communitySenseLineGetAllBoxSenseLineStatuses,
  communitySenseLineGetButtonMessages,
  communitySenseLineGetLatchMessages,
  communitySenseLineGetRecords,
} from "../../reducers/sense-line-reducer";
import CommunityManagerAppBar, { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import useSubscription from "../../utilities/hooks/useSubscription";
import nimbioServer from "../../server/endpoints";
import { latchStatusSubscription } from "../../subscriptions/latchStatusSubscription";

function CommunitySenseLine(props: any) {
  const { dispatch, sense_lines, sense_line_statuses } = props;
  const { boxId, communityId, latchId } = useParams();

  useSubscription(nimbioServer.latch + `.${latchId}`, latchStatusSubscription);

  useEffect(() => {
    // @ts-expect-error will always be string
    dispatch(communitySenseLineGetAllBoxSenseLines(parseInt(communityId ?? ""), boxId));
    // @ts-expect-error will always be string
    dispatch(communitySenseLineGetAllBoxSenseLineStatuses(parseInt(communityId ?? ""), boxId));
    dispatch(communitySenseLineGetLatchMessages(parseInt(communityId ?? "")));
    dispatch(communitySenseLineGetButtonMessages(parseInt(communityId ?? "")));
  }, [dispatch, boxId, latchId, communityId]);

  useEffect(() => {
    if (sense_lines.length === 0 || sense_line_statuses.length === 0) {
      return;
    }
    // @ts-expect-error box id is fine
    dispatch(communitySenseLineGetRecords(parseInt(communityId ?? ""), boxId, 1, 24));
  }, [dispatch, boxId, communityId, sense_lines, sense_line_statuses]);

  return (
    <CommunityManagerAppBar {...props} selected={APP_BAR_ITEMS.KEYS}>
      <SenseLineConfigure />
    </CommunityManagerAppBar>
  );
}

const mapStateToProps = (state: any) => {
  return {
    is_admin: state.profileState.details.is_admin,
    sense_lines: state.senseLine.box_sense_lines,
    sense_line_statuses: state.senseLine.box_sense_line_statuses,
  };
};

export default connect(mapStateToProps)(CommunitySenseLine);
