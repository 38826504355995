import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import DistributorAppBar from "./DistributorAppBar";
import { adminGetDeviceStatusPaginated } from "../../../reducers/device-status-reducer";
import DeviceList from "../../common/Components/DeviceList";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { deviceStatusSubscription } from "../../../subscriptions/deviceStatusSubscription";
import nimbioServer from "../../../server/endpoints";

const DistributorDeviceMonitor = (props: any) => {
  const dispatch = useDispatch();

  useSubscription(nimbioServer.internal.device.newBox, deviceStatusSubscription);

  useEffect(() => {
    dispatch(adminGetDeviceStatusPaginated(1, 24));
  }, []);

  return (
    <DistributorAppBar selected="Device Monitor" {...props}>
      <DeviceList type="revealed" />
    </DistributorAppBar>
  );
};

DistributorDeviceMonitor.propTypes = {};

const mapStateToProps = (state: any) => {
  return {
    deviceMonitor: state.deviceStatus,
  };
};

export default connect(mapStateToProps)(DistributorDeviceMonitor);
