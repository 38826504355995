import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  result: any;
  loaded: boolean;
}

export const initialState: State = {
  loading: false,
  error: false,
  result: null,
  loaded: false,
};

export const addVendorAPIKey = createAsyncAction("ADMIN_ADD_VENDOR_API_KEY", (vendor_uuid) => {
  return call(nimbioServer.admin.addVendorAPIKey, [vendor_uuid], "admin-add-vendor-api-key");
});

export const resetVendorAPIKey: any = createAction("ADMIN_ADD_VENDOR_API_KEY_RESET");

export default handleActions(
  {
    ADMIN_ADD_VENDOR_API_KEY_RESET: () => {
      return initialState;
    },
    ADMIN_ADD_VENDOR_API_KEY_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: null,
      };
    },
    ADMIN_ADD_VENDOR_API_KEY_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        loaded: true,
      };
    },
    ADMIN_ADD_VENDOR_API_KEY_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
