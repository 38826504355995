import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";

// Action Creators
export const getMemberDetail = createAsyncAction(
  "GET_MEMBER_DETAILS",
  (account_community_id: string, community_id: string | number) => {
    return call(nimbioServer.community.manager.getMember, [account_community_id, community_id], "memberdetail-reducer");
  },
  (account_community_id: string, community_id: string | number) => {
    return { account_community_id, community_id };
  }
);

export const refreshMemberDetail = createAsyncAction(
  "REFRESH_MEMBER_DETAILS",
  (account_community_id: string, community_id: string | number) => {
    return call(nimbioServer.community.manager.getMember, [account_community_id, community_id], "memberdetail-reducer");
  }
);

export const closeMemberDetail = createAction("CLOSE_MEMBER_DETAILS", () => false);

export const setMemberHome = createAsyncAction(
  "SET_MEMBER_HOME",
  (community_id: string | number, account_community_id: string, home_id: string) => {
    return call(
      nimbioServer.community.manager.setHome,
      [community_id, account_community_id, home_id],
      "memberdetail-reducer"
    );
  }
);

export const removeMember = createAsyncAction(
  "REMOVE_MEMBER",
  (account_community_id: string, keys_to_remove: string[], community_id: string | number, reason: string) => {
    return call(
      nimbioServer.community.manager.removeMember,
      [account_community_id, keys_to_remove, community_id, reason],
      "memberdetail-reducer"
    );
  }
);

interface State {
  details: object;
  loading: boolean;
  loadingSetHome: boolean;
  loadingRemove: boolean;
  error: boolean;
  errorSetHome: boolean;
  errorRemove: boolean;
  removed: boolean;
  open: boolean;
  is_home: boolean;
  home_id: string;
  account_community_id: string | boolean;
  community_id: string | boolean;
}

// Reducer
export const initialState: State = {
  details: {},
  loading: false,
  loadingSetHome: false,
  loadingRemove: false,
  error: false,
  errorSetHome: false,
  errorRemove: false,
  removed: false,
  open: false,
  is_home: false,
  home_id: "",
  account_community_id: false,
  community_id: false,
};

export default handleActions(
  {
    REFRESH_MEMBER_DETAILS_LOADING: (state: State) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    REFRESH_MEMBER_DETAILS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: false,
        loading: false,
        details: payload,
        is_home: false,
        home_id: payload.home == null ? "" : payload.home.id,
      };
    },
    REFRESH_MEMBER_DETAILS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        is_home: false,
        account_community_id: false,
        community_id: false,
      };
    },
    GET_MEMBER_DETAILS_LOADING: (state: State) => {
      return {
        ...state,
        open: true,
        loading: true,
        error: false,
      };
    },
    GET_MEMBER_DETAILS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: false,
        loading: false,
        details: payload,
        is_home: false,
        home_id: payload.home == null ? "" : payload.home.id,
      };
    },
    GET_MEMBER_DETAILS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        is_home: false,
        account_community_id: false,
        community_id: false,
      };
    },
    SET_MEMBER_HOME_LOADING: (state: State) => {
      return {
        ...state,
        loadingSetHome: true,
      };
    },
    SET_MEMBER_HOME_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        home_id: payload,
        loadingSetHome: false,
      };
    },
    SET_MEMBER_HOME_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        errorSetHome: payload,
        loadingSetHome: false,
      };
    },
    CLOSE_MEMBER_DETAILS: (state: State) => {
      return {
        ...state,
        open: false,
        account_community_id: false,
        community_id: false,
      };
    },
    REMOVE_MEMBER_LOADING: (state: State) => {
      return {
        ...state,
        loadingRemove: true,
        removed: false,
      };
    },
    REMOVE_MEMBER_SUCCESS: (state: State) => {
      return {
        ...state,
        removed: true,
        loadingRemove: false,
      };
    },
    REMOVE_MEMBER_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        removed: false,
        errorRemove: payload,
        loadingRemove: false,
      };
    },
  },
  initialState
);

// Selectors
