import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";

type AddTimeProps = {
  closeCallback: (start_time: string | null, end_time: string | null, cancel?: boolean) => void;
  open: boolean;
  latch_id: string;
};

type TimeState = {
  start_time: moment.Moment | null;
  end_time: moment.Moment | null;
  error: string | boolean;
};

const AddTime = (props: AddTimeProps) => {
  const initialState: TimeState = {
    start_time: null,
    end_time: null,
    error: false,
  };
  const holdOpenState = useSelector((state: any) => state.holdOpens);
  const [timeState, setTimeState] = useState<TimeState>(initialState);
  const [startTimeOpen, setStartTimeOpen] = useState<boolean>(false);
  const [endTimeOpen, setEndTimeOpen] = useState<boolean>(false);

  const holdOpens = holdOpenState.hold_opens[props.latch_id];
  const timezone = holdOpens.timezone;

  const _check_dates = (start_time: moment.Moment | null, end_time: moment.Moment | null) => {
    if (start_time && end_time) {
      if (parseInt(start_time.format("HH")) > parseInt(end_time.format("HH"))) {
        return "End time must be after start time";
      }
    }
    return false;
  };

  const _handleStartChange = (start_time: moment.Moment | null) => {
    setTimeState({ ...timeState, start_time, error: _check_dates(start_time, timeState.end_time) });
  };

  const _handleEndChange = (end_time: moment.Moment | null) => {
    setTimeState({ ...timeState, end_time, error: _check_dates(timeState.start_time, end_time) });
  };

  const _formatTime = (time: moment.Moment | null) => {
    return moment(time).format("HH:mm");
  };

  const handleCloseStartTime = (isCancel: boolean) => {
    if (isCancel) {
      setTimeState({ ...timeState, start_time: null });
    }
    setStartTimeOpen(false);
  };

  const handleCloseEndTime = (isCancel: boolean) => {
    if (isCancel) {
      setTimeState({ ...timeState, end_time: null });
    }
    setEndTimeOpen(false);
  };

  const _onOK = () => {
    props.closeCallback(_formatTime(timeState.start_time), _formatTime(timeState.end_time));
    setTimeState(initialState);
  };
  const _onCancel = () => {
    props.closeCallback(null, null, true);
    setTimeState(initialState);
  };
  const _handleOpenStartTime = () => {
    setStartTimeOpen(true);
    if (!timeState.start_time) {
      const start_time = moment.tz(moment(), timezone);
      const error = _check_dates(start_time, timeState.end_time);
      setTimeState({ ...timeState, start_time, error });
    }
  };
  const _handleOpenEndTime = () => {
    setEndTimeOpen(true);
    if (!timeState.end_time) {
      const end_time = moment.tz(moment().add(1, "hour"), timezone);
      const error = _check_dates(timeState.start_time, end_time);
      setTimeState({ ...timeState, end_time, error });
    }
  };

  return (
    <Dialog data-testid="add-time-dialog" maxWidth="lg" open={props.open}>
      <DialogTitle id="max-width-dialog-title">Add Time</DialogTitle>
      <DialogContent>
        <Box style={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography sx={{ paddingBottom: 2 }}>
            Select the start and end times for the recurring hold open event.
          </Typography>

          <Grid container style={{ justifyContent: "center" }} alignItems="center" direction="row">
            <Grid item>
              <Grid container sx={{ paddingTop: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={_handleOpenStartTime}
                    value={timeState.start_time ? moment(timeState.start_time).format("h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="Start Time"
                    data-testid="add-recurring-start-time"
                  />
                  <EventDateTimePicker
                    open={startTimeOpen}
                    setDateTimeCallback={(start_date) => _handleStartChange(start_date)}
                    secondPageTitle="Set Event Start Time"
                    datetime={timeState.start_time}
                    closeCallback={handleCloseStartTime}
                    startingPage="time"
                    firstPageTitle={""}
                  />
                </Grid>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={_handleOpenEndTime}
                    value={timeState.end_time ? moment(timeState.end_time).format("h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="End Time"
                    data-testid="add-recurring-end-time"
                  />
                  <EventDateTimePicker
                    open={endTimeOpen}
                    setDateTimeCallback={(start_date) => _handleEndChange(start_date)}
                    secondPageTitle="Set Event End Time"
                    datetime={timeState.end_time}
                    closeCallback={handleCloseEndTime}
                    startingPage="time"
                    firstPageTitle={""}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {timeState.error && (
            <Typography style={{ textAlign: "center", paddingTop: 1, color: "red" }}>{timeState.error}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={_onOK}
          disabled={!timeState.start_time || !timeState.end_time || !!timeState.error}
          variant="contained"
          data-testid="add-time-ok">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTime;
