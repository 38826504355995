import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  results: any[];
  loaded: boolean;
}

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteVendorFromBox = createAsyncAction(
  "REMOVE_VENDOR_FROM_BOX_DATA",
  (box_id: string, vendor_uuid: string) => {
    return call(nimbioServer.admin.removeVendorFromBox, [box_id, vendor_uuid], "admin-get-boxes-vendors");
  }
);

export const getBoxesVendors = createAsyncAction("ADMIN_GET_BOXES_VENDORS", () => {
  return call(nimbioServer.admin.getVendorByBox, [], "admin-get-boxes-vendors");
});

export default handleActions(
  {
    ADMIN_GET_BOXES_VENDORS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_BOXES_VENDORS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_BOXES_VENDORS_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
