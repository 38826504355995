import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { parseSenseLineRecords } from "../models/SenseLineModel";

interface State {
  box_sense_lines: any[];
  sense_line_records: any[];
  box_sense_line_statuses: any[];
  button_messages: any[];
  latch_messages: any[];
  error: boolean;
  loading: boolean;
}

const initialState: State = {
  box_sense_lines: [],
  sense_line_records: [],
  box_sense_line_statuses: [],
  button_messages: [],
  latch_messages: [],
  error: false,
  loading: false,
};

export const adminSenseLineGetAllBoxSenseLines = createAsyncAction(
  "ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINES",
  (box_id: string) => {
    return call(nimbioServer.admin.getAllBoxSenseLines, [box_id], "sense-line-reducer");
  }
);

export const communitySenseLineGetAllBoxSenseLines = createAsyncAction(
  "CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINES",
  (community_id: string | number, box_id: string) => {
    return call(nimbioServer.community.manager.getAllBoxSenseLines, [community_id, box_id], "sense-line-reducer");
  }
);

export const adminSenseLineGetRecords = createAsyncAction(
  "ADMIN_SENSE_LINE_GET_RECORDS",
  (box_id: string, page_number: number, results_per_page: number, sense_line_id: number | null = null) => {
    return call(
      nimbioServer.admin.getSenseLineRecordsPaginated,
      [box_id, page_number, results_per_page, sense_line_id],
      "sense-line-reducer"
    );
  }
);
export const communitySenseLineGetRecords = createAsyncAction(
  "CM_SENSE_LINE_GET_RECORDS",
  (
    community_id: string | number,
    box_id: string,
    page_number: number,
    results_per_page: number,
    sense_line_id: number | null = null
  ) => {
    return call(
      nimbioServer.community.manager.getSenseLineRecordsPaginated,
      [community_id, box_id, page_number, results_per_page, sense_line_id],
      "sense-line-reducer"
    );
  }
);
// TODO: fix TS error
// @ts-expect-error eph convert from will
export const senseLineUpdateNewRecord = createAsyncAction("SENSE_LINE_UPDATE_NEW_RECORD", (message) => {
  return {
    type: "SENSE_LINE_UPDATE_NEW_RECORD",
    payload: message,
  };
});

export const adminSenseLineGetAllBoxSenseLineStatuses = createAsyncAction(
  "ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES",
  (box_id: string) => {
    return call(nimbioServer.admin.getAllBoxSenseLineStatuses, [box_id], "sense-line-reducer");
  }
);

export const communitySenseLineGetAllBoxSenseLineStatuses = createAsyncAction(
  "CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES",
  (community_id: string | number, box_id: string) => {
    return call(
      nimbioServer.community.manager.getAllBoxSenseLineStatuses,
      [community_id, box_id],
      "sense-line-reducer"
    );
  }
);

export const adminSenseLineCreateSenseLine = createAsyncAction(
  "ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE",
  (box_id: string, sense_line_id: number, sense_line_online: boolean, latch_data_online: boolean) => {
    return call(
      nimbioServer.admin.createSenseLine,
      [box_id, sense_line_id, sense_line_online, latch_data_online],
      "sense-line-reducer"
    );
  }
);

export const communitySenseLineCreateSenseLine = createAsyncAction(
  "CM_SENSE_LINE_CREATE_BOX_SENSE_LINE",
  (
    community_id: string | number,
    box_id: string,
    sense_line_id: number,
    sense_line_online: boolean,
    latch_data_online: boolean
  ) => {
    return call(
      nimbioServer.community.manager.createSenseLine,
      [community_id, box_id, sense_line_id, sense_line_online, latch_data_online],
      "sense-line-reducer"
    );
  }
);

export const adminSenseLineUpdateBoxSenseLine = createAsyncAction(
  "ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE",
  (box_id: string, sense_line_id: number, sense_line_online: boolean, latch_data_online: boolean) => {
    return call(
      nimbioServer.admin.setSenseLine,
      [box_id, sense_line_id, sense_line_online, latch_data_online],
      "sense-line-reducer"
    );
  }
);

export const communitySenseLineUpdateBoxSenseLine = createAsyncAction(
  "CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE",
  (
    community_id: string | number,
    box_id: string,
    sense_line_id: number,
    sense_line_online: boolean,
    latch_data_online: boolean
  ) => {
    return call(
      nimbioServer.community.manager.setSenseLine,
      [community_id, box_id, sense_line_id, sense_line_online, latch_data_online],
      "sense-line-reducer"
    );
  }
);

export const adminSenseLineCreateSenseLineStatus = createAsyncAction(
  "ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS",
  (
    box_id: string,
    latch_id: string,
    sense_line_id: number,
    sense_line_state: number,
    transient: number,
    latch_message_id: number,
    button_message_id: number
  ) => {
    return call(
      nimbioServer.admin.createSenseLineStatus,
      [box_id, latch_id, sense_line_id, sense_line_state, transient, latch_message_id, button_message_id],
      "sense-line-reducer"
    );
  }
);

export const communitySenseLineCreateSenseLineStatus = createAsyncAction(
  "CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS",
  (
    community_id: string | number,
    box_id: string,
    latch_id: string,
    sense_line_id: number,
    sense_line_state: number,
    transient: number,
    latch_message_id: number,
    button_message_id: number
  ) => {
    return call(
      nimbioServer.community.manager.createSenseLineStatus,
      [community_id, box_id, latch_id, sense_line_id, sense_line_state, transient, latch_message_id, button_message_id],
      "sense-line-reducer"
    );
  }
);

export const adminSenseLineGetLatchMessages = createAsyncAction("ADMIN_SENSE_LINE_GET_LATCH_MESSAGES", () => {
  return call(nimbioServer.admin.getLatchMessages, [], "sense-line-reducer");
});

export const communitySenseLineGetLatchMessages = createAsyncAction(
  "CM_SENSE_LINE_GET_LATCH_MESSAGES",
  (communityId: string | number) => {
    return call(nimbioServer.community.manager.getLatchMessages, [communityId], "sense-line-reducer");
  }
);

export const adminSenseLineGetButtonMessages = createAsyncAction("ADMIN_SENSE_LINE_GET_BUTTON_MESSAGES", () => {
  return call(nimbioServer.admin.getButtonMessages, [], "sense-line-reducer");
});

export const communitySenseLineGetButtonMessages = createAsyncAction(
  "CM_SENSE_LINE_GET_BUTTON_MESSAGES",
  (communityId: string | number) => {
    return call(nimbioServer.community.manager.getButtonMessages, [communityId], "sense-line-reducer");
  }
);

export const communitySenseLineUpdateSenseLineStatus = createAsyncAction(
  "CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS",
  (
    community_id: string | number,
    box_id: string,
    sense_line_state: number,
    sense_line_id: number,
    latch_id: string,
    transient: number,
    latch_message_id: number,
    button_message_id: number
  ) => {
    return call(
      nimbioServer.community.manager.setSenseLineStatus,
      [
        community_id,
        box_id,
        sense_line_state,
        sense_line_id,
        ["latch_id", "transient", "latch_message_id", "button_message_id"],
        [latch_id, transient, latch_message_id, button_message_id],
      ],
      "sense-line-reducer"
    );
  }
);

export const adminSenseLineUpdateSenseLineStatus = createAsyncAction(
  "ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS",
  (
    box_id: string,
    sense_line_state: number,
    sense_line_id: number,
    latch_id: string,
    transient: number,
    latch_message_id: number,
    button_message_id: number
  ) => {
    return call(
      nimbioServer.admin.setSenseLineStatus,
      [
        box_id,
        sense_line_state,
        sense_line_id,
        ["latch_id", "transient", "latch_message_id", "button_message_id"],
        [latch_id, transient, latch_message_id, button_message_id],
      ],
      "sense-line-reducer"
    );
  }
);

export default handleActions(
  {
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: payload === false ? [] : payload,
      };
    },
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: payload === false ? [] : payload,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_GET_RECORDS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      if (state.box_sense_lines.length === 0 || state.box_sense_line_statuses.length === 0) {
        return {
          ...state,
          loading: false,
          error: payload === false,
          sense_line_records: [],
        };
      }
      const records_with_applied_transients = parseSenseLineRecords(state, payload);
      return {
        ...state,
        loading: false,
        error: payload === false,
        sense_line_records: records_with_applied_transients,
      };
    },
    ADMIN_SENSE_LINE_GET_RECORDS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_RECORDS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_GET_RECORDS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      if (state.box_sense_lines.length === 0 || state.box_sense_line_statuses.length === 0) {
        return {
          ...state,
          loading: false,
          error: payload === false,
          sense_line_records: [],
        };
      }
      const records_with_applied_transients = parseSenseLineRecords(state, payload);
      return {
        ...state,
        loading: false,
        error: payload === false,
        sense_line_records: records_with_applied_transients,
      };
    },
    CM_SENSE_LINE_GET_RECORDS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
        senseLineRecords: [],
      };
    },
    CM_SENSE_LINE_GET_RECORDS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: payload === false ? [] : payload,
      };
    },
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: payload === false ? [] : payload,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_GET_ALL_BOX_SENSE_LINE_STATUSES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_SUCCESS: (state: State, { payload }: { payload: any }) => {
      let updated_sense_lines;
      if (state.box_sense_lines.length === 0) {
        updated_sense_lines = [payload];
      } else {
        updated_sense_lines = [...state.box_sense_lines, payload];
      }
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: updated_sense_lines,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_SUCCESS: (state: State, { payload }: { payload: any }) => {
      let updated_sense_lines;
      if (state.box_sense_lines.length === 0) {
        updated_sense_lines = [payload];
      } else {
        updated_sense_lines = [...state.box_sense_lines, payload];
      }
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: updated_sense_lines,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_sense_lines = state.box_sense_lines.map((sense_line) => {
        if (sense_line.sense_line_id === payload.sense_line_id) {
          return payload;
        }
        return sense_line;
      });
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: updated_sense_lines,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_sense_lines = state.box_sense_lines.map((sense_line) => {
        if (sense_line.sense_line_id === payload.sense_line_id) {
          return payload;
        }
        return sense_line;
      });
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_lines: updated_sense_lines,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_statuses = [...state.box_sense_line_statuses, payload];
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: updated_statuses,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_statuses = [...state.box_sense_line_statuses, payload];
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: updated_statuses,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_CREATE_BOX_SENSE_LINE_STATUS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_statuses = state.box_sense_line_statuses.map((status) => {
        if (
          status.sense_line_id === payload.sense_line_id &&
          status.box_id === payload.box_id &&
          status.sense_line_state === payload.sense_line_state
        ) {
          return payload;
        }
        return status;
      });
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: updated_statuses,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const updated_statuses = state.box_sense_line_statuses.map((status) => {
        if (
          status.sense_line_id === payload.sense_line_id &&
          status.box_id === payload.box_id &&
          status.sense_line_state === payload.sense_line_state
        ) {
          return payload;
        }
        return status;
      });
      return {
        ...state,
        loading: false,
        error: payload === false,
        box_sense_line_statuses: updated_statuses,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_UPDATE_BOX_SENSE_LINE_STATUS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
      };
    },
    ADMIN_SENSE_LINE_GET_BUTTON_MESSAGES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        button_messages: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_BUTTON_MESSAGES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_BUTTON_MESSAGES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_GET_BUTTON_MESSAGES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        button_messages: payload,
      };
    },
    CM_SENSE_LINE_GET_BUTTON_MESSAGES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_GET_BUTTON_MESSAGES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_SENSE_LINE_GET_LATCH_MESSAGES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        latch_messages: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_LATCH_MESSAGES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_SENSE_LINE_GET_LATCH_MESSAGES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CM_SENSE_LINE_GET_LATCH_MESSAGES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        latch_messages: payload,
      };
    },
    CM_SENSE_LINE_GET_LATCH_MESSAGES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CM_SENSE_LINE_GET_LATCH_MESSAGES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    SENSE_LINE_UPDATE_NEW_RECORD: (state: State, { payload }: { payload: any }) => {
      const data = payload.payload;
      return {
        ...state,
        loading: false,
        error: payload === false,
        sense_line_records: [
          {
            sense_line: data.sense_line_id,
            state: data.sense_line_state,
            log_datetime: data.log_datetime,
            latch_message_label: data.latch_message_label,
          },
          ...state.sense_line_records,
        ],
      };
    },
  },
  initialState
);
