import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";
import { parseKeys } from "../models/KeysModel";
import { ServerResponseType } from "../utilities/nimbioTypes";

// Action Creators
export const getKeys = createAsyncAction("GET_KEYS", (community_id) =>
  call(nimbioServer.community.manager.getKeys, [community_id], "keys-reducer")
);

export const renameKey = createAsyncAction("CHANGE_NAME", (key_id, key_name, community_id) =>
  call(nimbioServer.community.manager.changeKeyName, [key_id, key_name, community_id], "keys-reducer")
);

export const disableCommunityKey = createAsyncAction("DISABLE_COMMUNITY_KEY", (key_id, community_id) =>
  call(nimbioServer.community.manager.disableCommunityKey, [key_id, community_id], "keys-reducer")
);

export const enableCommunityKey = createAsyncAction("ENABLE_COMMUNITY_KEY", (key_id, community_id) => {
  return call(nimbioServer.community.manager.enableCommunityKey, [key_id, community_id], "keys-reducer");
});

export const updateKeyStateLatchStatus = createAction("UPDATE_KEY_STATE_LATCH_STATUS", (message) => {
  return message;
});

export const updateKeyStateLatchOpened = createAction("UPDATE_KEY_STATE_LATCH_OPENED", (message) => {
  return message;
});

export const updateKeyStateLatchLoading = createAction("UPDATE_KEY_STATE_LATCH_LOADING", (message) => {
  return message;
});

export const updateKeyStateLatchFailed = createAction("UPDATE_KEY_STATE_LATCH_FAILED", (message) => {
  return message;
});

export const updateKeyStateLatchReset = createAction("UPDATE_KEY_STATE_RESET", (message) => {
  return message;
});

export const clearKeys = createAction("CLEAR_KEYS");

interface State {
  keys: any[];
  loading: boolean;
  loadingChangeName: boolean;
  error: boolean;
  errorMessage: string;
  errorChangeName: boolean;
  keysModel: object;
}

// Reducer
export const initialState = {
  keys: [],
  loading: false,
  loadingChangeName: false,
  error: false,
  errorMessage: "",
  errorChangeName: false,
  keysModel: {},
};

export default handleActions(
  {
    GET_KEYS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_KEYS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: false,
        loading: false,
        keysModel: parseKeys(payload),
        keys: payload,
      };
    },
    GET_KEYS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    CHANGE_NAME_LOADING: (state: State) => {
      return {
        ...state,
        loadingChangeName: true,
      };
    },
    CHANGE_NAME_SUCCESS: (state: State) => {
      return {
        ...state,
        loadingChangeName: false,
      };
    },
    CHANGE_NAME_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        errorChangeName: payload,
        loadingChangeName: false,
      };
    },
    UPDATE_KEY_STATE_LATCH_STATUS: (state: State, { payload }: { payload: any }) => {
      const updatedKeys = state.keys.map((key) => {
        const updatedLatches = key.latches.map((latch: any) => {
          if (latch.id === payload.latch_id) {
            return { ...latch, latch_status_current_message: payload };
          } else {
            return latch;
          }
        });
        return {
          ...key,
          latches: updatedLatches,
        };
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_OPENED: (state: State, { payload }: { payload: any }) => {
      const updatedKeys = state.keys.map((key) => {
        const updatedLatches = key.latches.map((latch: any) => {
          if (latch.id === payload.latch_id) {
            return {
              ...latch,
              opened: true,
              loading: false,
              failed: false,
            };
          }
          return latch;
        });
        return {
          ...key,
          latches: updatedLatches,
        };
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_LOADING: (state: State, { payload }: { payload: any }) => {
      const updatedKeys = state.keys.map((key) => {
        const updatedLatches = key.latches.map((latch: any) => {
          if (latch.id === payload.latch_id) {
            return {
              ...latch,
              opened: false,
              loading: true,
              failed: false,
            };
          }
          return latch;
        });
        return {
          ...key,
          latches: updatedLatches,
        };
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_FAILED: (state: State, { payload }: { payload: any }) => {
      const updatedKeys = state.keys.map((key) => {
        const updatedLatches = key.latches.map((latch: any) => {
          if (latch.id === payload.latch_id) {
            return {
              ...latch,
              opened: false,
              loading: false,
              failed: true,
            };
          }
          return latch;
        });
        return {
          ...key,
          latches: updatedLatches,
        };
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_RESET: (state: State, { payload }: { payload: any }) => {
      const updatedKeys = state.keys.map((key) => {
        const updatedLatches = key.latches.map((latch: any) => {
          if (latch.id === payload.latch_id) {
            return {
              ...latch,
              opened: false,
              loading: false,
              failed: false,
            };
          }
          return latch;
        });
        return {
          ...key,
          latches: updatedLatches,
        };
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    CLEAR_KEYS: () => {
      return {
        keys: [],
        loading: false,
        loadingChangeName: false,
        error: false,
        errorChangeName: false,
        keysModel: {},
        errorMessage: "",
      };
    },
    ENABLE_COMMUNITY_KEY_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          errorMessage: payload["message"],
          loading: false,
        };
      }

      return {
        ...state,
        loading: false,
      };
    },
    ENABLE_COMMUNITY_KEY_ERROR: (state: State) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    ENABLE_COMMUNITY_KEY_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    DISABLE_COMMUNITY_KEY_SUCCESS: (state: State, { payload }: { payload: ServerResponseType }) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          errorMessage: payload["message"],
          loading: false,
        };
      }

      return {
        ...state,
        loading: false,
      };
    },
    DISABLE_COMMUNITY_KEY_ERROR: (state: State) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    DISABLE_COMMUNITY_KEY_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
  initialState
);

// Selectors
