import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  results: any[];
  loaded: boolean;
}

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteVendorContactData = createAsyncAction(
  "DELETE_VENDOR_CONTACT_DATA",
  (vendor_contact_uuid: string) => {
    return call(nimbioServer.admin.deleteVendorContact, [vendor_contact_uuid], "admin-get-vendor-contacts");
  }
);

export const getContactsByVendor = createAsyncAction("ADMIN_GET_CONTACTS_BY_VENDOR", (vendor_uuid: string) => {
  return call(nimbioServer.admin.getVendorContactsByVendor, [vendor_uuid], "admin-get-vendor-contacts");
});

export const addVendorContact = createAsyncAction(
  "ADMIN_ADD_VENDOR_CONTACT",
  (vendor_contact_uuid: string, vendor_uuid: string, name: string, address: string, phone: string) =>
    call(
      nimbioServer.admin.addVendorContact,
      [vendor_contact_uuid, vendor_uuid, name, address, phone],
      "admin-get-vendor-contacts"
    )
);

export default handleActions(
  {
    ADMIN_GET_CONTACTS_BY_VENDOR_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_CONTACTS_BY_VENDOR_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_CONTACTS_BY_VENDOR_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
