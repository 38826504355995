import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

// Action Creators
export const getMemberChildren = createAsyncAction("GET_MEMBER_CHILDREN", (community_id, account_community_id) => {
  return call(
    nimbioServer.community.manager.viewChildren,
    [account_community_id, community_id],
    "member-children-reducer"
  );
});

export const adminGetMemberChildren = createAsyncAction(
  "ADMIN_GET_MEMBER_CHILDREN",
  (community_id, account_community_id) => {
    return call(
      nimbioServer.admin.community.viewChildren,
      [account_community_id, community_id],
      "member-children-reducer"
    );
  }
);

export const closeMemberChildren = createAction("CLOSE_MEMBER_CHILDREN", () => true);

interface State {
  details: any[];
  loading: boolean;
  error: boolean;
  open: boolean;
}
// Reducer
export const initialState: State = {
  details: [],
  loading: false,
  error: false,
  open: false,
};

export default handleActions(
  {
    CLOSE_MEMBER_CHILDREN: () => {
      return initialState;
    },
    GET_MEMBER_CHILDREN_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        open: true,
      };
    },
    GET_MEMBER_CHILDREN_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: false,
        loading: false,
        details: payload,
        open: true,
      };
    },
    GET_MEMBER_CHILDREN_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        open: true,
      };
    },
    ADMIN_GET_MEMBER_CHILDREN_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        open: true,
      };
    },
    ADMIN_GET_MEMBER_CHILDREN_SUCCESS: (state: State, { payload }: { payload: any }) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          loading: false,
          open: true,
        };
      }
      return {
        ...state,
        error: false,
        loading: false,
        details: payload["data"],
        open: true,
      };
    },
    ADMIN_GET_MEMBER_CHILDREN_ERROR: (state: State) => {
      return {
        ...state,
        error: true,
        loading: false,
        open: true,
      };
    },
  },
  initialState
);

// Selectors
