import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  results: any[];
  loaded: boolean;
}
const initialState: State = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteVendorAPIKeyData = createAsyncAction("DELETE_VENDOR_API_KEY_DATA", (vendor_api_key_uuid: string) => {
  return call(nimbioServer.admin.deleteVendorAPIKey, [vendor_api_key_uuid], "admin-get-vendor-keys");
});

export const getKeysByVendor = createAsyncAction("ADMIN_GET_KEYS_BY_VENDOR", (vendor_uuid: string) => {
  return call(nimbioServer.admin.getVendorAPIKeysByVendor, [vendor_uuid], "admin-get-vendor-keys");
});

export default handleActions(
  {
    ADMIN_GET_KEYS_BY_VENDOR_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_KEYS_BY_VENDOR_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_KEYS_BY_VENDOR_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
