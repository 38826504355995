import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

// Action Creators
export const setPhone = createAction("SET_PHONE", (phone_number) => ({ phone_number }));

export const sendPhone = createAsyncAction("SEND_PHONE", (phone_number) =>
  call(nimbioServer.auth.registerPhone, [phone_number], "login-reducer")
);

export const setOneTimeCode = createAction("SET_ONE_TIME_CODE", (one_time_code) => ({ one_time_code }));

export const sendOneTimeCode = createAsyncAction("SEND_ONE_TIME_CODE", (phone_number, one_time_code) => {
  return call(nimbioServer.auth.confirmPhoneNumber, [phone_number, one_time_code], "login-reducer");
});

export const sendFirstName = createAsyncAction(
  "SEND_FIRST_NAME",
  (first_name) => {
    return call(nimbioServer.account.setFirstName, first_name.trim(), "login-reducer");
  },
  (first_name) => first_name.trim()
);

export const sendLastName = createAsyncAction(
  "SEND_LAST_NAME",
  (last_name) => {
    return call(nimbioServer.account.setLastName, last_name.trim(), "login-reducer");
  },
  (last_name) => last_name.trim()
);

export const sendAccountData = createAsyncAction(
  "SEND_ACCOUNT_DATA",
  (account_id, first_name, last_name, is_admin, is_distributor, is_installer) => {
    return call(
      nimbioServer.admin.account.setAccountData,
      [account_id, first_name.trim(), last_name.trim(), is_admin, is_distributor, is_installer],
      "login-reducer"
    );
  }
);

export const clearLogin = createAction("CLEAR_LOGIN");

interface State {
  loading: boolean;
  error: string | null;
  phoneNumber: string;
  phoneNumberError: string;
  sendPhoneSuccess: boolean;
  oneTimeCode: string;
  oneTimeError: string;
  oneTimeCodeSuccess: boolean;
  auth_id: string;
  secret: string;
  sendFirstNameSuccess: boolean;
  sendLastNameSuccess: boolean;
  lastCodeRequest: number | null;
  sendAccountDataSuccess: boolean | null;
}

// Reducer
export const initialState: State = {
  loading: false,
  error: null,
  phoneNumber: "",
  phoneNumberError: "",
  sendPhoneSuccess: false,
  oneTimeCode: "",
  oneTimeError: "",
  oneTimeCodeSuccess: false,
  auth_id: "",
  secret: "",
  sendFirstNameSuccess: false,
  sendLastNameSuccess: false,
  lastCodeRequest: null,
  sendAccountDataSuccess: null,
};

export default handleActions(
  {
    SET_PHONE: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        phoneNumber: payload.phone_number,
        phoneNumberError: "",
      };
    },
    SET_ONE_TIME_CODE: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        oneTimeCode: payload.one_time_code,
        oneTimeError: "",
      };
    },
    SEND_ACCOUNT_DATA_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        sendAccountDataSuccess: false,
      };
    },
    SEND_ACCOUNT_DATA_SUCCESS: (state: State) => {
      return {
        ...state,
        sendAccountDataSuccess: true,
        loading: false,
      };
    },
    SEND_ACCOUNT_DATA_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        sendAccountDataSuccess: false,
        accountDataError: "Invalid account data",
        loading: false,
      };
    },
    SEND_FIRST_NAME_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    SEND_FIRST_NAME_SUCCESS: (state: State) => {
      return {
        ...state,
        sendFirstNameSuccess: true,
        loading: false,
      };
    },
    SEND_FIRST_NAME_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        firstNameError: "Invalid First Name",
        loading: false,
      };
    },
    SEND_LAST_NAME_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    SEND_LAST_NAME_SUCCESS: (state: State) => {
      return {
        ...state,
        sendLastNameSuccess: true,
        loading: false,
      };
    },
    SEND_LAST_NAME_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        lastNameError: "Invalid Last Name",
        loading: false,
      };
    },
    SEND_PHONE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        lastCodeRequest: null,
      };
    },
    SEND_PHONE_SUCCESS: (state: State) => {
      return {
        ...state,
        sendPhoneSuccess: true,
        loading: false,
        lastCodeRequest: Date.now(),
      };
    },
    SEND_PHONE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        sendPhoneSuccess: false,
        // TODO(rick) should we set payload here instead?
        phoneNumberError: "Invalid Phone Number",
        loading: false,
      };
    },
    SEND_ONE_TIME_CODE_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    SEND_ONE_TIME_CODE_SUCCESS: (state: State, { payload }: { payload: any }) => {
      if (payload) {
        localStorage.setItem("auth_id", payload.auth_id);
        localStorage.setItem("secret", payload.secret);
        return {
          ...state,
          oneTimeCodeSuccess: true,
          auth_id: payload.auth_id,
          secret: payload.secret,
          loading: false,
        };
      } else {
        return {
          ...state,
          oneTimeError: "Code is Incorrect",
          loading: false,
        };
      }
    },
    SEND_ONE_TIME_CODE_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        oneTimeError: "Code is Incorrect",
      };
    },
    CLEAR_LOGIN: () => {
      return initialState;
    },
  },
  initialState
);

// Selectors
