import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call, subscribe, unsubscribe } from "../utilities/connection";
import { holdOpenSubscription } from "../subscriptions/holdOpenSubscriptions";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  hold_opens: boolean | object;
  active_hold_open_subscriptions: string[];
}

const initialState: State = {
  loading: false,
  error: false,
  hold_opens: false,
  active_hold_open_subscriptions: [],
};

export const loadHoldOpens = createAsyncAction("LOAD_HOLD_OPENS", (community_id: string) => {
  return call(nimbioServer.community.manager.getHoldOpens, [community_id], "hold-open-reducer->loadHoldOpens");
});

export const refreshHoldOpens = createAsyncAction("REFRESH_HOLD_OPENS", (community_id: string) => {
  return call(nimbioServer.community.manager.getHoldOpens, [community_id], "hold-open-reducer->refreshHoldOpens");
});

export const addHoldOpenEvent = createAsyncAction(
  "ADD_HOLD_OPEN_EVENT",
  (community_id: string, latch_id: string, event_start_datetime: string, event_end_datetime: string) => {
    return call(
      nimbioServer.community.manager.box.addEventHoldOpens,
      [community_id, latch_id, event_start_datetime, event_end_datetime],
      "hold-open-reducer->addHoldOpenEvent"
    );
  }
);

export const removeHoldOpenEvent = createAsyncAction(
  "REMOVE_HOLD_OPEN_EVENT",
  (community_id: string, latch_id: string, event_id: string) => {
    return call(
      nimbioServer.community.manager.box.removeEventHoldOpens,
      [community_id, latch_id, event_id],
      "hold-open-reducer->removeHoldOpenEvent"
    );
  }
);

export const addHoldOpenRecurringEvent = createAsyncAction(
  "ADD_HOLD_OPEN_RECURRING_EVENT",
  (
    community_id: string | number,
    latch_id: string,
    days_of_the_week: string,
    recurring_week: number,
    start_time: string,
    end_time: string
  ) => {
    return call(
      nimbioServer.community.manager.box.addRecurringHoldOpens,
      [community_id, latch_id, days_of_the_week, recurring_week, start_time, end_time],
      "hold-open-reducer->addHoldOpenRecurringEvent"
    );
  }
);

export const removeHoldOpenRecurringEvent = createAsyncAction(
  "REMOVE_HOLD_OPEN_RECURRING_EVENT",
  (community_id: string, latch_id: string, temporal_date_id: string) => {
    return call(
      nimbioServer.community.manager.box.removeRecurringHoldOpen,
      [community_id, latch_id, temporal_date_id],
      "hold-open-reducer->removeHoldOpenRecurringEvent"
    );
  }
);

export const addHoldOpenTemporalTime = createAsyncAction(
  "ADD_HOLD_OPEN_TEMPORAL_TIME",
  (community_id: string, latch_id: string, temporal_date_id: string, start_time: string, end_time: string) => {
    return call(
      nimbioServer.community.manager.box.addRecurringTime,
      [community_id, latch_id, temporal_date_id, start_time, end_time],
      "hold-open-reducer->addHoldOpenTemporalTime"
    );
  }
);

export const removeHoldOpenTemporalTime = createAsyncAction(
  "REMOVE_HOLD_OPEN_TEMPORAL_TIME",
  (community_id: string, latch_id: string, temporal_date_id: string, temporal_time_id: string) => {
    return call(
      nimbioServer.community.manager.box.removeRecurringTime,
      [community_id, latch_id, temporal_date_id, temporal_time_id],
      "hold-open-reducer->removeHoldOpenTemporalTime"
    );
  }
);

export const cancelHoldOpensUntil = createAsyncAction(
  "CANCEL_HOLD_OPENS_UNTIL",
  (community_id: string, latch_id: string, disable_until: string) => {
    return call(
      nimbioServer.community.manager.disableHoldOpensUntil,
      [community_id, latch_id, disable_until],
      "hold-open-reducer->cancelHoldOpenUntil"
    );
  }
);

export const enableHoldOpens = createAsyncAction("ENABLE_HOLD_OPENS", (community_id: string, latch_id: string) => {
  return call(
    nimbioServer.community.manager.enableHoldOpens,
    [community_id, latch_id],
    "hold-open-reducer->enableHoldOpens"
  );
});

export default handleActions(
  {
    LOAD_HOLD_OPENS_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    LOAD_HOLD_OPENS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      const new_hold_open_subscriptions = Object.keys(payload);
      state.active_hold_open_subscriptions.map((latch_id) => unsubscribe(latch_id, holdOpenSubscription));
      new_hold_open_subscriptions.map((latch_id) =>
        subscribe(`${nimbioServer.communityManager.latch}.` + latch_id, holdOpenSubscription)
      );
      return {
        ...state,
        loading: false,
        hold_opens: payload,
        error: payload === false,
        active_hold_open_subscriptions: new_hold_open_subscriptions,
      };
    },
    LOAD_HOLD_OPENS_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        hold_opens: false,
      };
    },
    REFRESH_HOLD_OPENS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        hold_opens: payload,
        error: payload === false,
      };
    },
  },
  initialState
);
