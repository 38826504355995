import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import { parseCommunities } from "../models/CommunityModel";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";

interface State {
  details: any[];
  loading: boolean;
  error: any;
  currentCommunityID: string | null;
  communityModel: any;
  currentCommunity: any;
  currentCommunityIDLoaded: boolean;
  communitiesLoaded: boolean;
}

// Action Creators
export const getCommunities = createAsyncAction("GET_COMMUNITIES", () =>
  call(nimbioServer.community.manager.getCommunities, [], "communities-reducer")
);

export const setCurrentCommunity = createAction("SET_CURRENT_COMMUNITY", (community_id: string | number) => {
  return community_id;
});

export const clearCommunities = createAction("CLEAR_COMMUNITIES");

// Reducer
const initialState = {
  details: [],
  loading: false,
  error: null,
  currentCommunityID: null,
  communityModel: {},
  currentCommunity: {},
  currentCommunityIDLoaded: false,
  communitiesLoaded: false,
};

export default handleActions(
  {
    GET_COMMUNITIES_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_COMMUNITIES_SUCCESS: (state: State, { payload }: { payload: any }) => {
      if (payload["result"] === false) {
        return {
          ...state,
          details: false,
          loading: false,
          communitiesLoaded: true,
        };
      }
      return {
        ...state,
        details: payload,
        communityModel: parseCommunities(payload),
        loading: false,
        communitiesLoaded: true,
      };
    },
    GET_COMMUNITIES_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    SET_CURRENT_COMMUNITY: (state: State, { payload }: { payload: any }) => {
      // Deterimine if the current community is loaded
      let loaded = false;
      if (!state.communityModel[payload] && !state.loading) {
        loaded = true;
      }
      if (state.communityModel[payload] && !state.loading) {
        loaded = true;
      }

      return {
        ...state,
        currentCommunityID: payload,
        currentCommunityIDLoaded: loaded,
        currentCommunity: state.communityModel[payload],
      };
    },
    CLEAR_COMMUNITIES: () => {
      return initialState;
    },
  },
  initialState
);

// Selectors
