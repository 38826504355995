import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";
import { ServerResponseType } from "../utilities/nimbioTypes";

type universalSearchResultType = {
  id: string;
  match: string;
  table_name: string;
};

interface State {
  loading: boolean;
  error: boolean;
  loaded: boolean;
  universalSearchResult: universalSearchResultType[];
  universalSearchTotalResults: number;
  universalSearchQuery: string;
}

const initialState: State = {
  loading: false,
  error: false,
  loaded: false,
  universalSearchResult: [],
  universalSearchTotalResults: 0,
  universalSearchQuery: "",
};

export const setUniversalSearchQuery = createAction("ADMIN_SET_UNIVERSAL_SEARCH_QUERY", (query: string) => {
  return query;
});

export const searchUniversal = createAsyncAction(
  "ADMIN_UNIVERSAL_SEARCH",
  (search: string, page: number, size: number) => {
    return call(nimbioServer.admin.universalSearch, [search, page, size], "admin-find-user");
  }
);

export const clearUniversalSearch = createAction("ADMIN_CLEAR_UNIVERSAL_SEARCH", () => {
  return true;
});

export default handleActions(
  {
    ADMIN_UNIVERSAL_SEARCH_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_UNIVERSAL_SEARCH_SUCCESS: (state, { payload }: { payload: ServerResponseType }) => {
      if (!payload["result"]) {
        return {
          ...state,
          loading: false,
          universalSearchResult: [],
          error: true,
        };
      }
      return {
        ...state,
        loading: false,
        universalSearchResult: payload.data["results"],
        universalSearchTotalResults: payload.data["total_length"],
      };
    },
    ADMIN_UNIVERSAL_SEARCH_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
    ADMIN_CLEAR_UNIVERSAL_SEARCH: (state: State) => {
      return {
        ...state,
        universalSearchResult: [],
        universalSearchTotalResults: 0,
        universalSearchQuery: "",
      };
    },
    ADMIN_SET_UNIVERSAL_SEARCH_QUERY: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        universalSearchQuery: payload,
      };
    },
  },
  initialState
);
