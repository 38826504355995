import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import AddTime from "./AddTime";
import WeekdayButton from "./WeekdayButton";
import { addHoldOpenRecurringEvent } from "../../../reducers/hold-open-reducers";

const styles = () => ({
  text: {
    fontFamily: "Filson",
  },
});

type AddRecurringProps = {
  community_id: number;
  latch_id: string;
  closeCallback: () => void;
  open: boolean;
};

type daysOfTheWeek = {
  U: boolean;
  M: boolean;
  T: boolean;
  W: boolean;
  H: boolean;
  F: boolean;
  S: boolean;
};

const AddRecurring = (props: AddRecurringProps) => {
  const dispatch = useDispatch();
  const { community_id, latch_id, closeCallback, open } = props;
  const [addTimeOpen, setAddTimeOpen] = useState(false);
  const [recurringWeek, setRecurringWeek] = useState("1");
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<daysOfTheWeek>({
    U: false,
    M: false,
    T: false,
    W: false,
    H: false,
    F: false,
    S: false,
  });

  const setInitialState = () => {
    setRecurringWeek("1");
    setDaysOfTheWeek({
      U: false,
      M: false,
      T: false,
      W: false,
      H: false,
      F: false,
      S: false,
    });
  };

  const _dotwHandler = (dotw: keyof daysOfTheWeek) => {
    setDaysOfTheWeek((oldDaysOfTheWeek) => {
      return { ...oldDaysOfTheWeek, [dotw]: !oldDaysOfTheWeek[dotw] };
    });
  };

  const submitEvent = (start_time: string, end_time: string) => {
    const dotw = (Object.keys(daysOfTheWeek) as Array<keyof daysOfTheWeek>)
      .filter((key) => daysOfTheWeek[key])
      .reduce((accum, key) => accum + key, "");
    dispatch(addHoldOpenRecurringEvent(community_id, latch_id, dotw, parseInt(recurringWeek), start_time, end_time));
    setInitialState();
    closeCallback();
  };

  const _open_add_time = () => {
    setAddTimeOpen(true);
  };

  const _close_add_time = (start_time: string | null, end_time: string | null, is_cancel: boolean = false) => {
    if (!is_cancel && start_time && end_time) {
      submitEvent(start_time, end_time);
      setAddTimeOpen(false);
    } else {
      setAddTimeOpen(false);
    }
  };

  const _onCancel = () => {
    setInitialState();
    closeCallback();
  };

  const _checkDisableOK = () => {
    return !Object.values(daysOfTheWeek).reduce((accum, value) => accum || value, false);
  };

  return (
    <Dialog data-testid="dotw-recurring-event-dialog" maxWidth="lg" open={open}>
      <DialogTitle id="max-width-dialog-title" sx={styles().text}>
        Add Recurring Hold Open
      </DialogTitle>
      <DialogContent>
        <Box style={{ paddingTop: 1, paddingBottom: 3 }}>
          <Typography sx={styles().text}>Select the days for the recurring hold open event.</Typography>
        </Box>
        {/*<FormControl component="fieldset">*/}
        {/*  <FormLabel component="legend">Repeating Week</FormLabel>*/}
        {/*  <RadioGroup row aria-label="position" name="position" defaultValue="1">*/}
        {/*    <FormControlLabel*/}
        {/*      value="1"*/}
        {/*      control={<Radio color="primary" />}*/}
        {/*      label="Every Week"*/}
        {/*      labelPlacement="bottom"*/}
        {/*    />*/}
        {/*    <FormControlLabel*/}
        {/*      value="start"*/}
        {/*      control={<Radio color="primary" />}*/}
        {/*      label="Every Other Week"*/}
        {/*      labelPlacement="bottom"*/}
        {/*    />*/}
        {/*  </RadioGroup>*/}
        {/*</FormControl>*/}
        <Typography sx={[{ paddingTop: 2, paddingBottom: 2 }, styles().text]}>Days of the Week</Typography>
        <Grid container spacing={1}>
          <WeekdayButton dayLabel="Su" serverKey="U" handleClick={_dotwHandler} isActive={daysOfTheWeek["U"]} />
          <WeekdayButton dayLabel="M" serverKey="M" handleClick={_dotwHandler} isActive={daysOfTheWeek["M"]} />
          <WeekdayButton dayLabel="Tu" serverKey="T" handleClick={_dotwHandler} isActive={daysOfTheWeek["T"]} />
          <WeekdayButton dayLabel="W" serverKey="W" handleClick={_dotwHandler} isActive={daysOfTheWeek["W"]} />
          <WeekdayButton dayLabel="Th" serverKey="H" handleClick={_dotwHandler} isActive={daysOfTheWeek["H"]} />
          <WeekdayButton dayLabel="F" serverKey="F" handleClick={_dotwHandler} isActive={daysOfTheWeek["F"]} />
          <WeekdayButton dayLabel="Sa" serverKey="S" handleClick={_dotwHandler} isActive={daysOfTheWeek["S"]} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={_open_add_time} disabled={_checkDisableOK()} variant="contained" color="primary">
          Add Time
        </Button>
      </DialogActions>
      <AddTime open={addTimeOpen} closeCallback={_close_add_time} latch_id={latch_id} />
    </Dialog>
  );
};

export default AddRecurring;
