import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  results: any[];
  loaded: boolean;
}

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const getVendorByUUID = createAsyncAction("ADMIN_GET_VENDOR_BY_UUID", (vendor_uuid: string) => {
  return call(nimbioServer.admin.getVendorByUUID, [vendor_uuid], "admin-get-vendor-by-uuid");
});

export default handleActions(
  {
    ADMIN_GET_VENDOR_BY_UUID_LOADING: (state: State) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_VENDOR_BY_UUID_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_VENDOR_BY_UUID_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
