import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";

// Action Creators
export const getHomeDetail = createAsyncAction(
  "GET_HOME_DETAILS",
  (home_id: string, community_id: string) => {
    return call(nimbioServer.community.manager.getHomeDetails, [home_id, community_id], "homedetail-reducer");
  },
  (home_id, community_id) => {
    return { home_id, community_id };
  }
);

export const closeHomeDetail = createAction("CLOSE_HOME_DETAILS", () => false);

// TODO(rick) the SET_OWNER_OCCUPIED actions don't appear to be handled anywhere
export const setOwnerOccupied = createAsyncAction(
  "SET_OWNER_OCCUPIED",
  (community_id: string, home_id: string, owner_occupied: boolean) => {
    return call(
      nimbioServer.community.manager.setOwnerOccupied,
      [community_id, home_id, owner_occupied],
      "homedetail-reducer"
    );
  }
);

export const setShowHomeMembersInList = createAction(
  "SET_SHOW_HOME_MEMBERS_IN_LIST",
  (show_home_members_in_list: boolean) => {
    return { show_home_members_in_list };
  }
);

interface State {
  details: object;
  loading: boolean;
  error: boolean;
  open: boolean;
  is_home: boolean;
  community_id: string;
  home_id: string;
  home_cache: object;
  show_home_members_in_list: boolean;
}

// Reducer
const initialState: State = {
  details: {},
  loading: false,
  error: false,
  open: false,
  is_home: false,
  community_id: "",
  home_id: "",
  home_cache: {},
  show_home_members_in_list: false,
};

export default handleActions(
  {
    CLOSE_HOME_DETAILS: (state) => {
      return {
        ...state,
        open: false,
      };
    },
    GET_HOME_DETAILS_LOADING: (state, { meta }: { meta: any }) => {
      return {
        ...state,
        open: true,
        loading: true,
        error: false,
        community_id: meta.community_id,
        home_id: meta.home_id,
      };
    },
    GET_HOME_DETAILS_SUCCESS: (state, { payload, meta }: { payload: any; meta: any }) => {
      return {
        ...state,
        open: true,
        error: payload["result"] === false,
        loading: false,
        details: payload,
        is_home: true,
        home_cache: {
          ...state.home_cache,
          [meta.home_id]: payload,
        },
      };
    },
    GET_HOME_DETAILS_ERROR: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        is_home: false,
      };
    },
    SET_SHOW_HOME_MEMBERS_IN_LIST: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        show_home_members_in_list: payload.show_home_members_in_list,
      };
    },
  },
  initialState
);

// Selectors
