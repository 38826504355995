import React, { useState } from "react";
import "../../App.css";
import { connect, useSelector } from "react-redux";
import {
  Typography,
  Box,
  Card,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";
import { call } from "../../utilities/connection";
import nimbioServer from "../../server/endpoints";

const SendCommunityMessage = (props: any) => {
  const communityId = useSelector((state: any) => state.communityState.currentCommunityID);
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { first_name, last_name } = useSelector((state: any) => state.profileState.details);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    const result = await call(
      nimbioServer.community.manager.sendMessage,
      [communityId, message],
      "send-community-message"
    );
    if (!result) {
      setError("Internal server error.");
    } else if (!result["result"]) {
      setError(result["message"]);
    } else {
      setSuccessMessage(result["message"]);
    }
    closeConfirm();
  };
  const closeConfirm = () => {
    setConfirmOpen(false);
  };

  const openConfirm = () => {
    if (message.length === 0) {
      setError("Please enter a message");
      return;
    }
    if (message.length + first_name.length + last_name.length + 4 > 1000) {
      setError("Message must be less than 1000 characters");
      return;
    }
    setConfirmOpen(true);
  };

  const clearSuccessMessage = () => {
    setSuccessMessage("");
    setMessage("");
  };

  const clearMessage = () => {
    setMessage("");
    setError("");
  };

  if (!communityId) {
    navigate("/pick-community");
    return;
  }

  return (
    <div>
      <CommunityManagerAppBar selected={APP_BAR_ITEMS.SEND_MESSAGE} {...props}>
        <Card sx={{ padding: 4 }}>
          <Typography color="primary" variant="h6" sx={{ marginBottom: 2 }}>
            Community Messenger
          </Typography>
          {!successMessage ? (
            <Box>
              {error ? (
                <Box sx={{ paddingBottom: 4 }}>
                  <Typography color={"error"}>{error}</Typography>
                </Box>
              ) : (
                <Typography sx={{ paddingBottom: 4 }} color="textSecondary" variant="body1">
                  Enter a short message to send to all community members.
                </Typography>
              )}

              <Box>
                <TextField
                  data-testid="send-community-message-text-field"
                  value={message}
                  variant="outlined"
                  fullWidth
                  label={"Message"}
                  autoFocus={true}
                  onChange={handleMessageChange}
                />
              </Box>

              <Box display="flex" justifyContent={"flex-end"} paddingTop={4}>
                <Button sx={{ marginRight: 1 }} onClick={clearMessage} variant="outlined">
                  Clear
                </Button>
                <Button onClick={openConfirm} variant={"contained"}>
                  Send
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CheckCircleIcon sx={{ marginRight: 1, marginTop: 0.5, color: "green" }} />
                <Typography color="green" variant="body1" sx={{ paddingTop: 0.5 }}>
                  {successMessage} - Message Sent
                </Typography>
              </Box>
              <Box display="flex" justifyContent={"flex-end"} paddingTop={4}>
                <Button onClick={clearSuccessMessage} variant={"contained"}>
                  Ok
                </Button>
              </Box>
            </Box>
          )}
        </Card>
        <Dialog
          fullWidth={true}
          open={confirmOpen}
          onClose={closeConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogTitle>Are you sure you would like to send this message to all community members?</DialogTitle>
            <Box sx={{ textWrap: "wrap" }} padding={3}>
              <DialogContentText sx={{ textWrap: "wrap" }}>
                {message} - {first_name} {last_name}
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeConfirm}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSend}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </CommunityManagerAppBar>
    </div>
  );
};

export default connect()(SendCommunityMessage);
