import { handleActions } from "redux-actions";
import { createAction } from "redux-promise-middleware-actions";

// Action Creators
export const openRemovedMemberDetails = createAction("OPEN_REMOVED_MEMBER_DETAILS", (details) => details);
export const closeRemovedMemberDetails = createAction("CLOSE_REMOVED_MEMBER_DETAILS", () => false);

interface State {
  details: any;
  loading: boolean;
  error: boolean;
  removedMemberDetailsOpen: boolean;
}
// Reducer
export const initialState: State = {
  details: {},
  loading: false,
  error: false,
  removedMemberDetailsOpen: false,
};

export default handleActions(
  {
    OPEN_REMOVED_MEMBER_DETAILS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        removedMemberDetailsOpen: true,
        details: payload,
      };
    },
    CLOSE_REMOVED_MEMBER_DETAILS: (state: State) => {
      return {
        ...state,
        removedMemberDetailsOpen: false,
      };
    },
  },
  initialState
);

// Selectors
