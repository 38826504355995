import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import DatasetIcon from "@mui/icons-material/Dataset";
import StoreIcon from "@mui/icons-material/Store";
import LockIcon from "@mui/icons-material/Lock";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import {
  clearUniversalSearch,
  searchUniversal,
  setUniversalSearchQuery,
} from "../../../reducers/admin-universal-search-reducer";
import PaginationComponent from "../../common/Components/PaginationComponent";

type AdminSearchDataResult = {
  id: string;
  table_name: string;
  match: string;
};

const AdminUniversalSearch = () => {
  const { universalSearchResult, universalSearchTotalResults, universalSearchQuery } = useSelector(
    (state: any) => state.universalSearch
  );
  const [query, setQuery] = useState(universalSearchQuery);
  const [totalSearchResults, setTotalSearchResults] = useState(universalSearchTotalResults);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100;
  const navigate = useNavigate();
  const queryDebounceTimer = useRef<any>(null);

  const dispatch = useDispatch();

  const search = (value: string) => {
    if (value === "") {
      dispatch(clearUniversalSearch());
      return;
    }
    setCurrentPage(1);
    setQuery(value);
    dispatch(setUniversalSearchQuery(value));
    dispatch(searchUniversal(value, 1, pageSize));
  };

  const paginateUniversalSearch = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(searchUniversal(query, pageNumber, pageSize));
  };

  useEffect(() => {
    setTotalSearchResults(universalSearchTotalResults);
  }, [universalSearchTotalResults]);

  useEffect(() => {
    if (query !== "") {
      search(query);
    }
  }, []);

  const getResultIcon = (table_name: string) => {
    switch (table_name) {
      case "account":
        return <AccountCircleIcon />;
      case "auth_account":
        return <LocalPhoneIcon />;
      case "community":
        return <HolidayVillageIcon />;
      case "box":
        return <DatasetIcon />;
      case "latch":
        return <LockIcon />;
      case "key":
        return <KeyIcon />;
      case "vendor":
        return <StoreIcon />;
    }
  };

  const handleClick = (data: AdminSearchDataResult) => {
    switch (data["table_name"]) {
      case "account":
        navigate(`/admin/account/${data["id"]}`);
        break;
      case "auth_account":
        navigate(`/admin/account/${data["id"]}`);
        break;
      case "community":
        navigate(`/admin/community/${data["id"]}`);
        break;
      case "box":
        navigate(`/admin/device/${data["id"]}`);
        break;
      case "latch":
        navigate(`/admin/device/${data["id"]}`);
        break;
      case "key":
        navigate(`/admin/community/${data["id"]}`);
        break;
      case "vendor":
        navigate(`/admin/vendor/${data["id"]}`);
        break;
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Typography color="textSecondary" sx={{ paddingLeft: 2, paddingBottom: 2 }} variant="h6">
          Universal Search
        </Typography>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            data-testid="admin-universal-search-input"
            label="Query"
            defaultValue={query}
            variant="outlined"
            fullWidth
            onChange={(event) => {
              clearTimeout(queryDebounceTimer.current);
              queryDebounceTimer.current = setTimeout(() => {
                search(event.target.value.trim());
              }, 500);
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mb={2}>
        <Grid item></Grid>
      </Grid>
      <Grid flex={1}>
        {universalSearchResult.length > 0 ? (
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Results
              </ListSubheader>
            }>
            {universalSearchResult.map((data: AdminSearchDataResult, index: number) => {
              return (
                <ListItemButton
                  onClick={() => handleClick(data)}
                  key={data.id + index}
                  sx={{ display: "flex", width: "100%" }}>
                  <ListItemIcon>{getResultIcon(data["table_name"])}</ListItemIcon>
                  <ListItemText
                    data-testid={"admin-universal-search-result"}
                    primary={data["match"]}
                    secondary={data["table_name"]}
                  />
                </ListItemButton>
              );
            })}
          </List>
        ) : (
          <Typography sx={{ paddingLeft: 2 }}>No results</Typography>
        )}
        <PaginationComponent
          totalItems={totalSearchResults}
          itemsPerPage={pageSize}
          paginate={paginateUniversalSearch}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default connect()(AdminUniversalSearch);
