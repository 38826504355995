import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

interface State {
  loading: boolean;
  error: boolean;
  results: any[];
  loaded: boolean;
}
const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const sendVendorData = createAsyncAction("SEND_VENDOR_DATA", (id: string, name: string) => {
  return call(nimbioServer.admin.addVendor, [id, name], "admin-get-vendors");
});

export const disableVendor = createAsyncAction("DISABLE_VENDOR", (vendor_uuid: string) => {
  return call(nimbioServer.admin.disableVendor, [vendor_uuid], "admin-disable-vendor");
});

export const enableVendor = createAsyncAction("ENABLE_VENDOR", (vendor_uuid: string) => {
  return call(nimbioServer.admin.enableVendor, [vendor_uuid], "admin-enable-vendor");
});

export const sendAccountToVendorData = createAsyncAction(
  "SEND_ACCOUNT_TO_VENDOR_DATA",
  (account_id: string, vendor_uuid: string) => {
    return call(nimbioServer.admin.addVendorToAccount, [account_id, vendor_uuid], "admin-get-vendors");
  }
);

export const sendVendorToBoxData = createAsyncAction(
  "SEND_VENDOR_TO_BOX_DATA",
  (box_id: string, vendor_uuid: string) => {
    return call(nimbioServer.admin.addVendorToBox, [box_id, vendor_uuid], "admin-get-vendors");
  }
);

export const getVendors = createAsyncAction("ADMIN_GET_VENDORS", (show_disabled: boolean) => {
  return call(nimbioServer.admin.getVendors, [show_disabled], "admin-get-vendors");
});

export default handleActions(
  {
    ADMIN_GET_VENDORS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_VENDORS_SUCCESS: (state: State, { payload }: { payload: any }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_VENDORS_ERROR: (state: State) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
